import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { IConsumerPortalAddressData } from '@schemas/consumerPortal/types/addressData';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { getErrorMessage } from '@utils/getErrorMessage';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { EditAddressForm } from './EditAddressForm';
import { AddressRequest, IAddressPostRequest } from './types';

interface IUseEditAddressModalProps extends ISharedModalProps {
  consumerUuid?: string;
  data?: IConsumerPortalAddressData;
}

export const useEditAddressModal = (
  props: IUseEditAddressModalProps,
): Modal => {
  const { consumerUuid, data } = props;
  const { translate } = useI18n();
  const url = consumerUuid
    ? `${Endpoints.FetchUserAddress}/${consumerUuid}`
    : Endpoints.FetchUserAddress;

  const modal = useModal({
    sharedProps: props,
    modalName: 'editAddressModal',
    permissions: [
      'consumer_portal.change_consumeruser',
      'ops_portal.can_change_address',
    ],
    buttonTranslationKey: 'PAGE.ACCOUNT_SETTINGS.EDIT_ADDRESS',
  });

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    string,
    AxiosError,
    IAddressPostRequest
  >(
    request =>
      postDataToEndpoint({
        endpoint: url,
        requestData: request.data,
        options: { search: `?addressFormat=${request.addressFormat}` },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CONSUMER_ADDRESS]);
        queryClient.invalidateQueries([
          QueryKeys.CONSUMER_DETAILS,
          consumerUuid,
        ]);
        modal.resolveForm(
          translate('PAGE.ACCOUNT_SETTINGS.ADDRESS_SUCCESSFULLY_EDITED'),
        );
      },
      onError: error => {
        modal.resolveForm(
          getErrorMessage({
            error,
            genericErrorMessageKey:
              'PAGE.ACCOUNT_SETTINGS.UNABLE_TO_EDIT_ADDRESS',
            translate,
          }),
        );
      },
    },
  );

  const handleSubmit: AddressRequest = useCallback(
    request => {
      mutate(request);
    },
    [mutate],
  );

  const formID = 'edit-address';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={modal.formStatusMessage}
      isLoading={isLoading}
    >
      <EditAddressForm
        data={data}
        formID={formID}
        handleSubmit={handleSubmit}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    title: translate('PAGE.ACCOUNT_SETTINGS.EDIT_ADDRESS'),
  };

  return getModalDetails({ modal, modalProps });
};
