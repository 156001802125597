/* eslint-disable max-len */
import { useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getDatesToFromToday } from '@components/TableV2/helpers/getDatesToFromToday';
import { formatQueryParams } from '@components/TableV2/helpers/utils';
import {
  ITableConfig,
  IToggleSettings,
  LimitOffsetPaginationModel,
} from '@components/TableV2/types';
import { Translate } from '@hooks/useI18n';
import { useLimitOffsetPagination } from '@hooks/useModal/hooks/useLimitOffsetPagination';
import { useTableSorting } from '@hooks/useTableSorting/useTableSorting';
import { IHandleAgencyScreenChange } from '@pages/AgencyAssignments/AgencyAssignments';
import { IOpsPortalAggregateAgencyAssignments } from '@schemas/opsPortal/types/aggregateAgencyAssignments';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint/fetchDataFromEndpoint';
import { defaultAggregateAgencyAssignments } from '../../constants';
import { IAggregateAgencyAssignmentsFilterValues } from '../../types';
import { getUniqueDates } from '../../utils/getUniqueDates';
import { useGetAggregatedAgencyAssignmentsByAgencyBucketDays } from './useGetAggregatedAgencyAssignmentsByAgencyBucketDays';
import { useGetAggregatedAgencyAssignmentsByAgencyBucketWeeks } from './useGetAggregatedAgencyAssignmentsByAgencyBucketWeeks';
import { useGetAggregatedAgencyAssignmentsColumnsDays } from './useGetAggregatedAgencyAssignmentsColumnsDays';
import { useGetAggregatedAgencyAssignmentsColumnsWeeks } from './useGetAggregatedAgencyAssignmentsColumnsWeeks';
import { useGetAggregatedAgencyAssignmentsFilters } from './useGetAggregatedAgencyAssignmentsFilters';

interface IUseGetAggregatedAgencyAssignmentsTableConfig {
  handleAgencyScreenChange: ({
    filters,
    isOnAggregateScreen,
  }: IHandleAgencyScreenChange) => void;
  translate: Translate;
}

interface IAgencyAssigmentsTableConfig
  extends ITableConfig<
    IAggregateAgencyAssignmentsFilterValues,
    LimitOffsetPaginationModel,
    IOpsPortalAggregateAgencyAssignments
  > {
  hasDates: boolean;
}

export const useGetAggregatedAgencyAssignmentsTableConfig = ({
  handleAgencyScreenChange,
  translate,
}: IUseGetAggregatedAgencyAssignmentsTableConfig): IAgencyAssigmentsTableConfig => {
  const [breakdownByAgency, setBreakdownByAgency] = useState(false);
  const handleSwitch = () => {
    setBreakdownByAgency(prev => !prev);
  };

  const toggleSettings: IToggleSettings = {
    handleSwitch,
    value: breakdownByAgency,
    label: translate('OPS_PORTAL.LABEL.BREAKDOWN_BY_AGENCY'),
  };

  const sortingSettings = useTableSorting();
  const paginationSettings = useLimitOffsetPagination({
    isClientsidePagination: true,
  });

  const { from, to } = getDatesToFromToday(7);
  const initialDefaultFilterValues: IAggregateAgencyAssignmentsFilterValues = {
    assignmentStartDateFrom: from,
    assignmentStartDateTo: to,
  };

  const filtersSettings = useGetAggregatedAgencyAssignmentsFilters({
    initialDefaultValues: initialDefaultFilterValues,
    translate,
  });

  const {
    filterValues: { assignmentStartDateFrom, assignmentStartDateTo },
  } = filtersSettings;

  const stringifiedQueryParams = useMemo(
    () => formatQueryParams(filtersSettings.filterValues as object),
    [filtersSettings.filterValues],
  );

  const queryKey = useMemo(
    () => [QueryKeys.AGENCY_ASSIGMENTS, stringifiedQueryParams],
    [stringifiedQueryParams],
  );

  const {
    data: agencyAssignmentData,
    error,
    isLoading,
  } = useQuery<IOpsPortalAggregateAgencyAssignments, AxiosError>(queryKey, () =>
    fetchDataFromEndpoint(Endpoints.FetchAgencyAssignmentsAggregated, {
      query: {
        assignmentStartDateFrom: assignmentStartDateFrom ?? '',
        assignmentStartDateTo: assignmentStartDateTo ?? '',
      },
    }),
  );

  const uniqueAssignmentDates = useMemo(
    () =>
      getUniqueDates(agencyAssignmentData ?? defaultAggregateAgencyAssignments),
    [agencyAssignmentData],
  );

  const columnsByDay = useGetAggregatedAgencyAssignmentsColumnsDays({
    handleAgencyScreenChange,
    dates: uniqueAssignmentDates,
    translate,
  });

  const columnsByWeek = useGetAggregatedAgencyAssignmentsColumnsWeeks({
    handleAgencyScreenChange,
    dates: uniqueAssignmentDates,
    translate,
  });

  const columnsByAgencyByDay =
    useGetAggregatedAgencyAssignmentsByAgencyBucketDays({
      handleAgencyScreenChange,
      dates: uniqueAssignmentDates,
      translate,
    });

  const columnsByAgencyByWeeks =
    useGetAggregatedAgencyAssignmentsByAgencyBucketWeeks({
      handleAgencyScreenChange,
      dates: uniqueAssignmentDates,
      translate,
    });

  const columnsByBucketOnly = useMemo(
    () => (uniqueAssignmentDates.length > 10 ? columnsByWeek : columnsByDay),
    [uniqueAssignmentDates, columnsByWeek, columnsByDay],
  );

  const columnsBrokenDownByAgency = useMemo(
    () =>
      uniqueAssignmentDates.length > 10
        ? columnsByAgencyByWeeks
        : columnsByAgencyByDay,
    [uniqueAssignmentDates, columnsByAgencyByWeeks, columnsByAgencyByDay],
  );

  return {
    columns: toggleSettings?.value
      ? columnsBrokenDownByAgency
      : columnsByBucketOnly,
    data: agencyAssignmentData ?? defaultAggregateAgencyAssignments,
    hasDates: !!uniqueAssignmentDates.length,
    error,
    filtersSettings,
    isLoading,
    paginationSettings,
    sortingSettings,
    toggleSettings,
  };
};
