import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { IApplicationsTableData, Portal } from '@appTypes';
import { Link } from '@components/Common.styles';
import { StatusPill } from '@components/StatusPill';
import { ICustomTableColumn } from '@schemas/merchantPortal/types/accountSettings';
import { Portals } from '@utils/constants';
import { getCustomTableColumns } from '@utils/getCustomTableColumns';
import { ApplicationReferenceLink } from './ApplicationReferenceLink';
import { PaymentPlanLink } from './PaymentPlanLink';
import { IGetApplicationsTableColumnsProps } from './types';

const getPaymentLink = (
  isMerchantPortal: boolean,
  contractId: string,
  paymentPlanId: string,
) =>
  isMerchantPortal ? (
    <PaymentPlanLink contractId={contractId} paymentPlanId={paymentPlanId} />
  ) : (
    <span>{paymentPlanId}</span>
  );

const getPracticaLink = (
  isMerchantPortal: boolean,
  praticaId: string,
  contractId: string,
) =>
  isMerchantPortal ? (
    <span>{praticaId}</span>
  ) : (
    <Link to={contractId}>{praticaId}</Link>
  );

const getPortalConfig = (
  portal?: Portal,
  isCompass = false,
): ICustomTableColumn[] => {
  const practicaId: ICustomTableColumn[] = isCompass
    ? [{ dataKey: 'praticaId' }]
    : [];
  const originator: ICustomTableColumn[] = isCompass
    ? []
    : [{ dataKey: 'originator' }];
  const creditType: ICustomTableColumn[] = isCompass
    ? []
    : [{ dataKey: 'creditType' }];

  const portalConfigs: Record<Portal, ICustomTableColumn[]> = {
    [Portals.CONSUMER]: [
      { dataKey: 'applicationReference' },
      ...practicaId,
      { dataKey: 'createdDate' },
      { dataKey: 'merchant' },
      { dataKey: 'activityStatus' },
      { dataKey: 'amount' },
      { dataKey: 'originationChannel' },
      ...originator,
      { dataKey: 'crifScore' },
    ],
    [Portals.MERCHANT]: [
      { dataKey: 'paymentPlanId' },
      ...practicaId,
      { dataKey: 'merchantOrderReference' },
      { dataKey: 'createdDate' },
      { dataKey: 'customerName' },
      { dataKey: 'activityStatus' },
      { dataKey: 'amount' },
      ...creditType,
    ],
    [Portals.OPS]: [
      { dataKey: 'applicationReference' },
      ...practicaId,
      { dataKey: 'applicationUuid' },
      { dataKey: 'paymentPlanId' },
      { dataKey: 'createdDate' },
      { dataKey: 'customerName' },
      { dataKey: 'merchant' },
      { dataKey: 'activityStatus' },
      { dataKey: 'amount' },
      { dataKey: 'originationChannel' },
      ...originator,
      { dataKey: 'crifScore' },
      { dataKey: 'language' },
      ...creditType,
    ],
  };

  return portal ? portalConfigs[portal] : [];
};

export const getApplicationsTableColumns = ({
  isCompass = false,
  isPagolightPro = false,
  merchantColumnConfig,
  portal,
  translate,
}: IGetApplicationsTableColumnsProps) => {
  const columnHelper = createColumnHelper<IApplicationsTableData>();
  const isConsumer = portal === Portals.CONSUMER;
  const isMerchantPortal = portal === Portals.MERCHANT;
  const portalConfig: ICustomTableColumn[] =
    merchantColumnConfig ?? getPortalConfig(portal, isCompass);

  const defaultColumns: Partial<
    Record<
      keyof IApplicationsTableData,
      ColumnDef<IApplicationsTableData, string>
    >
  > = {
    amount: columnHelper.accessor('amount', {
      header: translate('LABEL.AMOUNT'),
    }),
    applicationReference: columnHelper.accessor('applicationReference', {
      header: translate('OPS_PORTAL.TITLE.APPLICATION_REFERENCE'),
      cell: ({ row }) => (
        <ApplicationReferenceLink data={row.original} isConsumer={isConsumer} />
      ),
    }),
    applicationUuid: columnHelper.accessor('applicationUuid', {
      header: translate('OPS_PORTAL.TITLE.APPLICATION_UUID'),
      enableSorting: false,
    }),
    creditType: columnHelper.accessor('creditType', {
      header: translate('LABEL.PRODUCT'),
      enableSorting: false,
    }),
    paymentPlanId: columnHelper.accessor('paymentPlanId', {
      header: translate('TITLE.PAYMENT_PLAN_ID'),
      enableSorting: false,
      cell: ({
        row: {
          original: { contractId, paymentPlanId },
        },
      }) => getPaymentLink(isMerchantPortal, contractId, paymentPlanId),
    }),
    createdDate: columnHelper.accessor('createdDate', {
      header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
    }),
    crifScore: columnHelper.accessor('crifScore', {
      header: translate('OPS_PORTAL.TITLE.CRIF_SCORE'),
      enableSorting: false,
    }),
    customerName: columnHelper.accessor('customerName', {
      header: translate('TITLE.CUSTOMER_NAME'),
      enableSorting: false,
    }),
    language: columnHelper.accessor('language', {
      header: translate('LABEL.LANGUAGE'),
    }),
    merchant: columnHelper.accessor('merchant', {
      header: translate('LABEL.MERCHANT_NAME'),
    }),
    merchantOrderReference: columnHelper.accessor('merchantOrderReference', {
      header: translate(
        isMerchantPortal && !isPagolightPro
          ? 'TITLE.YOUR_INVOICE_NUMBER'
          : 'TITLE.INVOICE_NUMBER',
      ),
      enableSorting: false,
    }),
    originationChannel: columnHelper.accessor('originationChannel', {
      header: translate('OPS_PORTAL.TITLE.ORIGINATION'),
      enableSorting: false,
    }),
    originator: columnHelper.accessor('originator', {
      header: translate('OPS_PORTAL.TITLE.ORIGINATOR'),
      enableSorting: false,
    }),
    praticaId: columnHelper.accessor('praticaId', {
      header: translate('TITLE.EXTERNAL_ID'),
      cell: ({
        row: {
          original: { contractId, praticaId },
        },
      }) => getPracticaLink(isMerchantPortal, praticaId, contractId),
      enableSorting: false,
    }),
    activityStatus: columnHelper.accessor('activityStatus', {
      header: translate('TITLE.STATUS'),
      cell: ({ row }) => {
        const { activityStatus, rawStatus } = row.original;
        return <StatusPill rawStatus={rawStatus} status={activityStatus} />;
      },
    }),
    contractId: columnHelper.accessor('contractId', {
      header: translate('OPS_PORTAL.TITLE.ORIGINATION'),
      enableSorting: false,
    }),
    storeNumber: columnHelper.accessor('storeNumber', {
      header: translate('TITLE.STORE_NUMBER'),
      enableSorting: false,
    }),
    productExternalId: columnHelper.accessor('productExternalId', {
      header: translate('TITLE.PRODUCT_EXTERNAL_ID'),
      enableSorting: false,
    }),
    productSku: columnHelper.accessor('productSku', {
      header: translate('LABEL.SKU'),
      enableSorting: false,
    }),
  };

  return getCustomTableColumns(portalConfig, defaultColumns, translate);
};
