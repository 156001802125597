import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IOpsPortalClawbackExtraData } from '@schemas/opsPortal/types/clawbackExtraData';
import { IOpsPortalClawbacks } from '@schemas/opsPortal/types/clawbacks';

import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { postDataToEndpoint } from '@utils/postDataToEndpoint/postDataToEndpoint';
import { IClawbacksCombinedData } from '../types';

export const useGetClawbackData = () => {
  const {
    data: clawbackListData,
    error: clawbackListError,
    isLoading: isClawbackListLoading,
  } = useQuery<IOpsPortalClawbacks, AxiosError>(
    [QueryKeys.CLAWBACKS_LIST],
    () => fetchDataFromEndpoint(Endpoints.FetchClawbacksList),
  );

  const contracts = [
    ...new Set(clawbackListData?.results.map(x => x.contract_uuid)),
  ];

  const {
    data: clawbackExtraData,
    error: clawbackExtraError,
    isLoading: isClawbackExtraLoading,
  } = useQuery<IOpsPortalClawbackExtraData, AxiosError>(
    [QueryKeys.CLAWBACKS_EXTRA, contracts],
    () =>
      postDataToEndpoint({
        endpoint: Endpoints.FetchClawbacksExtra,
        requestData: { contracts },
      }),
    {
      enabled: !!clawbackListData,
    },
  );

  const data: IClawbacksCombinedData[] =
    clawbackListData && clawbackExtraData
      ? clawbackListData.results.map(clawback => {
          const contractInfo = clawbackExtraData.results.find(
            c => c.contract_uuid === clawback.contract_uuid,
          );

          return {
            ...clawback,
            merchant_name: contractInfo?.merchant_name,
            merchant_status: contractInfo?.merchant_status,
            external_uuid: contractInfo?.external_uuid,
            payment_plan: contractInfo?.payment_plan,
          };
        })
      : [];

  const isLoading = isClawbackListLoading || isClawbackExtraLoading;
  const error = clawbackListError || clawbackExtraError;

  return { data, error, isLoading };
};
