import { Translate } from '@hooks/useI18n';
import { ICreditType } from '@schemas/merchantPortal/types/orders';

interface IFormatCreditTypeLabel {
  creditType?: ICreditType;
  translate: Translate;
}

export const formatCreditTypeLabel = ({
  creditType,
  translate,
}: IFormatCreditTypeLabel) =>
  creditType
    ? translate(
        `LABEL.CREDIT_TYPE_${creditType?.toUpperCase().replace(/\s+/g, '_')}`,
      )
    : null;
