import { useCallback } from 'react';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { useFileUploadQuery } from '@hooks/useFileUploadQuery';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { FileUploadModalContent } from './FileUploadModalContent';
import { IUploadFileRequest, IUseFileUploadModalProps } from './types';
import { useDocumentType } from './useDocumentType';

export const useFileUploadModal = (props: IUseFileUploadModalProps): Modal => {
  const {
    acceptedFiles,
    additionalButtonContent,
    dependencyValues,
    externalContractUuid,
  } = props;
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const documentTypeProps = useDocumentType({
    dependencyValues,
  });

  const { endpoint } = documentTypeProps.documentType ?? {};

  const formID = 'file-upload-form';

  const modal = useModal({
    sharedProps: props,
    modalName: 'fileUploadModal',
    permissions: ['merchant_portal.can_upload_signed_agreement'],
    buttonTranslationKey: 'BUTTON.UPLOAD_CUSTOMER_DOCUMENT',
  });

  const { isLoading, mutate } = useFileUploadQuery({
    endpoint: `${endpoint}/${externalContractUuid}`,
    onError: () => message.error('ERROR.FILE_UPLOAD', { timeout: 10000 }),
    onSuccess: () => {
      message.success('GENERAL.FILE_UPLOAD_SUCCESS', { noTimeout: true });
      modal.closeModal();
    },
  });

  const handleFileUpload = useCallback(
    (data: IUploadFileRequest) => mutate(data.file),
    [mutate],
  );

  const modalProps = {
    additionalButtonContent,
    cancelText: translate('BUTTON.CLOSE'),
    formID,
    isDangerousSubmit: true,
    modalContent: (
      <FileUploadModalContent
        acceptedFiles={acceptedFiles}
        documentTypeProps={documentTypeProps}
        formId={formID}
        handleFileUpload={handleFileUpload}
      />
    ),
    showFormButtons: true,
    submitText: translate('BUTTON.UPLOAD'),
    submitDisabled: isLoading,
    title: translate('BUTTON.UPLOAD_CUSTOMER_DOCUMENT'),
  };

  return getModalDetails({ modal, modalProps });
};
