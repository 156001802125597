import { styled } from '@mui/material/styles';
import { AuthPageHeader } from '../../Auth.styles';
import { ILoginPageHeader } from './types';

const StyledParagraph = styled('p')({
  paddingTop: '2rem',
});

export const LoginMFASetupPageHeader = ({ translate }: ILoginPageHeader) => (
  <AuthPageHeader>
    <h2>{translate('OPS_PORTAL.TITLE.SETUP_MFA')}</h2>
    <StyledParagraph>
      {translate('OPS_PORTAL.TITLE.SETUP_MFA_INSTRUCTIONS')}
    </StyledParagraph>
  </AuthPageHeader>
);
