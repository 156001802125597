import { Chip } from '@mui/material';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ILateFeeIActionStatus } from '@schemas/opsPortal/types/lateFees';

interface IActionStatusPillProps {
  status: ILateFeeIActionStatus;
}

const variants = {
  success: { backgroundColor: '#E6F4EA', color: '#34A853' },
  neutral: { backgroundColor: '#E8EAED', color: '#5F6368' },
  error: { backgroundColor: '#FFEBEE', color: '#EA4335' },
};

const statusToVariant = {
  COMPLETED: variants.success,
  PENDING: variants.success,
  PAUSED: variants.neutral,
  DELAYED: variants.neutral,
  CANCELLED: variants.neutral,
  FAILED: variants.error,
};

export const ActionStatusPill = ({ status }: IActionStatusPillProps) => {
  const { translate } = useI18n();
  const style = statusToVariant[status] || variants.neutral;
  return <Chip label={translate(`OPS_PORTAL.STATUS.${status}`)} sx={style} />;
};
