import { Translate } from '@hooks/useI18n';
import { IAgencyAssignedContractsFilterValues } from '../types';

export const generateTitle = (
  filterValues: IAgencyAssignedContractsFilterValues,
  translate: Translate,
) => {
  const {
    agencyName,
    assignmentStartDateFrom,
    assignmentStartDateTo,
    bucketName,
  } = filterValues;

  const coversMoreThanOneDay =
    assignmentStartDateFrom !== assignmentStartDateTo;

  const updatedAgencyName = agencyName.length
    ? agencyName
    : translate('OPS_PORTAL.LABEL.ALL_AGENCIES');

  const translationParams = {
    agency_name: updatedAgencyName,
    assignment_start_date_from: assignmentStartDateFrom,
    assignment_start_date_to: assignmentStartDateTo,
    bucket_name: bucketName,
  };

  return translate(
    `OPS_PORTAL.TITLE.AGENCY_ASSIGNED_CONTRACTS_${
      coversMoreThanOneDay ? 'DATE_RANGE' : 'DAY'
    }`,
    translationParams,
  );
};
