import {
  TableColumns,
  createTableColumnsV2,
} from '@components/TableV2/helpers/createTableColumnsV2';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n/types';
import { IAgencyContract } from '@schemas/opsPortal/types/agencyAssignedContracts';
import { getBucketColumn } from '../../utils/getBucketColumn';

export interface IUseGetAgencyAssignedContractsColumns {
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const useGetAgencyAssignedContractsColumns = ({
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IUseGetAgencyAssignedContractsColumns) => {
  const columns: TableColumns<IAgencyContract> = [
    {
      field: 'payment_plan_reference',
      label: translate('OPS_PORTAL.LABEL.PAYMENT_PLAN_REFERENCE'),
    },
    {
      field: 'agency_name',
      label: translate('OPS_PORTAL.LABEL.AGENCY'),
    },
    getBucketColumn(translate),
    {
      field: 'start_date',
      label: translate('LABEL.START_DATE'),
      mapCustomValue: ({ row }) => getLocaleDate({ date: row.start_date }),
    },
    {
      field: 'end_date',
      label: translate('LABEL.END_DATE'),
      mapCustomValue: ({ row }) => getLocaleDate({ date: row.end_date }),
    },
    {
      field: 'total_amount_collected',
      label: translate('OPS_PORTAL.LABEL.AMOUNT_COLLECTED'),
      mapCustomValue: ({ row }) =>
        row.total_amount_collected
          ? getLocaleCurrencyValue({
              currency: row.total_amount_collected.currency,
              value: row.total_amount_collected.amount,
            }) ?? ''
          : '',
    },
  ];

  return createTableColumnsV2({ columns });
};
