import styled from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n';
import { RiskTestOutcomes, missingValuePlaceholder } from '@utils/constants';
import {
  IOutcomeWrapperProps,
  IRiskTestsData,
  IRiskTestOutcome,
} from './types';

const OutcomeWrapper = styled.span.attrs({
  'aria-hidden': true,
})<IOutcomeWrapperProps>`
  padding-inline-start: 1rem;
  line-height: 1;
  font-size: 1.5rem;
  color: ${({ outcome }) => {
    if (outcome === RiskTestOutcomes.GREEN) {
      return '#008001';
    }
    if (outcome === RiskTestOutcomes.RED) {
      return '#f00';
    }
    return '#999';
  }};
`;

const dict: Record<IRiskTestOutcome, string> = {
  [RiskTestOutcomes.GREEN]: '✔',
  [RiskTestOutcomes.RED]: '✗',
  [RiskTestOutcomes.UNKNOWN]: missingValuePlaceholder,
};

export const getRiskTestsTableColumns = (translate: Translate) => {
  const columnHelper = createColumnHelper<IRiskTestsData>();

  return [
    columnHelper.accessor('testName', {
      header: translate('OPS_PORTAL.TITLE.RISK_TEST_NAME'),
    }),
    columnHelper.accessor('testResult', {
      header: translate('OPS_PORTAL.TITLE.RESULT'),
    }),
    columnHelper.accessor('testOutcome', {
      header: translate('OPS_PORTAL.TITLE.OUTCOME'),
      cell: ({ cell }) => {
        const outcome = cell.getValue() as IRiskTestOutcome;
        return (
          <>
            <span className="sr-only">
              {Object.values(RiskTestOutcomes).includes(outcome)
                ? outcome
                : RiskTestOutcomes.UNKNOWN}
            </span>
            <OutcomeWrapper outcome={outcome}>
              {dict[outcome] || missingValuePlaceholder}
            </OutcomeWrapper>
          </>
        );
      },
    }),
  ];
};
