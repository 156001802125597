import { AnyAction, Dispatch } from 'redux';
import { actions } from '@auth/_redux';
import { getUserMfaSettings } from '@auth/_redux/authCrud';
import { LoginAuthStep } from '@auth/pages/enums';
import { ICommonMfaSettings } from '@schemas/common/types/mfaSettings';
import { HandleStepChange } from '../AuthForm/types';

interface IGetUserMfaSettings {
  accessToken: string;
  dispatch: Dispatch<AnyAction>;
  handleStepChange: HandleStepChange;
}

export interface IGetUserMfaSettingsResponse {
  data: ICommonMfaSettings;
}

export const updateStepFromUserMfaSettings = async ({
  accessToken,
  dispatch,
  handleStepChange,
}: IGetUserMfaSettings) => {
  const { data }: IGetUserMfaSettingsResponse = await getUserMfaSettings();

  const {
    mfa_available: isMfaAvailable,
    mfa_enforced: isMfaEnforced,
    mfa_enrolled: isMfaEnrolled,
    totp_url: totpUrl,
  } = data;

  if (!isMfaAvailable) {
    dispatch(actions.login(accessToken));
    return;
  }

  const isSkippable = !isMfaEnforced;
  if (isMfaEnrolled) {
    handleStepChange({
      step: LoginAuthStep.Step2MfaCodeEntryPage,
      accessToken,
      options: { isSkippable },
    });
  } else {
    handleStepChange({
      step: LoginAuthStep.Step3MfaSetupPage,
      accessToken,
      options: {
        totpUrlString: totpUrl,
        isSkippable,
      },
    });
  }
};
