/* eslint-disable react/jsx-no-bind */
import { ClickableCell } from '@components/ClickableCell/ClickableCell';
import { TableColumns } from '@components/TableV2/helpers/createTableColumnsV2';
import {
  HandleScreenChange,
  IAgencyAssignmentCountData,
  IWeeklyDateGroup,
} from '../types';
import { getUniqueDatesByWeek } from './getUniqueDatesByWeek';

export const generateColumnsByWeeks = (
  dates: string[],
  handleAgencyScreenChange: HandleScreenChange,
): TableColumns<IAgencyAssignmentCountData> => {
  const dataByWeek = getUniqueDatesByWeek(dates);

  const handleCellClick = (
    row: IAgencyAssignmentCountData,
    dateFrom: string,
    dateTo: string,
  ) => {
    const agencyName = row?.agency ?? '';
    handleAgencyScreenChange({
      isOnAggregateScreen: false,
      filters: {
        agencyName,
        assignmentStartDateFrom: dateFrom,
        assignmentStartDateTo: dateTo,
        bucketName: row.bucket,
      },
    });
  };

  return dataByWeek.map((week: IWeeklyDateGroup) => {
    const { from, includedDates, to } = week;
    const label = `${from} - ${to}`;
    return {
      field: label,
      label,
      mapCustomJSX: ({ row }) => {
        const handleClick = () => handleCellClick(row, from, to);
        let total = 0;
        includedDates.forEach(includedDate => {
          total += row.assignmentCounts?.[includedDate] ?? 0;
        });
        return total ? (
          <ClickableCell onClick={handleClick} text={total} />
        ) : (
          total
        );
      },
    };
  });
};
