import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import {
  IMapApplicationDataProps,
  IOverviewData,
} from '@pages/ApplicationDetails/types';
import {
  dictionary,
  paymentMethodDictionary,
  riskStatusDictionary,
} from '@utils/dictionary';
import { getTranslatedData } from '@utils/getTranslatedData';
import { replaceSpacesAndCapitalise } from '@utils/replaceSpacesAndCapitalise';
import { CheckoutLink } from '../components/CheckoutLink';

export const mapApplicationData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isCompass,
  nextPayment,
  translate,
}: IMapApplicationDataProps) => {
  const {
    applicationReference,
    contractUuid: contractUUID,
    created,
    creditType,
    externalContractId: praticaId,
    merchantName,
    merchantOrderReference,
    numberOfInstallments,
    opProductName: productName,
    opSubproductName: subProductName,
    originationChannel,
    paymentMethod,
    paymentPlanReference: paymentPlanId,
    riskConfigName,
    status: activityStatus,
    storeId,
    uuid: applicationUuid,
    waterfallConfigName,
  } = objectKeysToCamelCase(data);

  const { amount } = data.order_details ?? {};

  const {
    credit_status: creditStatus,
    identity_status: identityStatus,
    payment_method_status: paymentMethodStatus,
  } = data.risk_details ?? {};

  const { amount: nextPaymentAmount } = nextPayment ?? {};

  const mappedData = {
    applicationReference,
    paymentPlanId,
    contractUUID,
    applicationUuid,
    merchantName,
    merchantOrderReference,
    productName,
    subProductName,
    storeId,
    ...(isCompass && { praticaId }),
    createdDate: created && getLocaleDate({ date: created }),
    applicationAmount:
      amount &&
      getLocaleCurrencyValue({
        currency: amount.currency,
        value: amount.amount,
      }),
    numberOfInstallments,
    monthlyPaymentAmount: nextPaymentAmount
      ? getLocaleCurrencyValue({
          currency: nextPaymentAmount.currency,
          value: nextPaymentAmount.amount,
        })
      : '',
    activityStatus: getTranslatedData({
      dict: dictionary.api_label,
      term: activityStatus,
      translate,
    }),
    creditStatus: getTranslatedData({
      dict: riskStatusDictionary,
      term: creditStatus,
      translate,
    }),
    identityStatus: getTranslatedData({
      dict: riskStatusDictionary,
      translate,
      term: identityStatus,
    }),
    paymentMethodStatus: getTranslatedData({
      dict: riskStatusDictionary,
      translate,
      term: paymentMethodStatus,
    }),
    paymentMethod: getTranslatedData({
      dict: paymentMethodDictionary,
      translate,
      term: paymentMethod,
    }),
    creditType:
      creditType &&
      translate(
        `OPS_PORTAL.CREDIT_TYPE.${replaceSpacesAndCapitalise(creditType)}`,
      ),
    riskConfigName,
    waterfallConfigName,
    ...(originationChannel === 'in_store'
      ? { checkoutLink: <CheckoutLink data={data} /> }
      : {}),
  };

  return addPlaceholderForEmptyValues<IOverviewData>(mappedData);
};
