import { IAddressComponentArray } from '@schemas/consumerPortal/types/addressData';
import { CustomComponentTypes } from '../enums';
import { IAddressValues } from '../types';

interface IGetCustomAddressComponentsProps {
  addressComponents: IAddressComponentArray;
  formValues: IAddressValues;
}

export const getCustomAddressComponents = ({
  addressComponents,
  formValues,
}: IGetCustomAddressComponentsProps) => {
  const formComponents: IAddressComponentArray = [
    {
      long_name: formValues.address_street_number ?? '',
      short_name: formValues.address_street_number ?? '',
      types: [CustomComponentTypes.StreetNumber],
    },
    {
      long_name: formValues.address_first_line ?? '',
      short_name: formValues.address_first_line ?? '',
      types: [CustomComponentTypes.Route],
    },
    {
      long_name: formValues.address_second_line ?? '',
      short_name: formValues.address_second_line ?? '',
      types: [CustomComponentTypes.SecondLine],
    },
    {
      long_name: formValues.address_post_code ?? '',
      short_name: formValues.address_post_code ?? '',
      types: [CustomComponentTypes.PostalCode],
    },
    {
      long_name: formValues.address_town ?? '',
      short_name: formValues.address_town ?? '',
      types: [CustomComponentTypes.PostalTown],
    },
  ];

  return (
    addressComponents
      // We don't need or want components with 'political' types.
      .filter(
        component =>
          component.types.includes('country') ||
          !component.types.includes('political'),
      )
      .reduce((previous, current) => {
        const isCustomComponent = Object.keys(CustomComponentTypes).some(key =>
          current.types.includes(
            CustomComponentTypes[key as keyof typeof CustomComponentTypes],
          ),
        );

        return [...previous, ...(isCustomComponent ? [] : [current])];
      }, formComponents)
  );
};
