import { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { actions, loginMFA } from '@auth/_redux';
import {
  ButtonWrapper,
  AuthButtonWrapper,
  Spinner,
  SpinnerButton,
  CenteringWrapper,
} from '@auth/pages/Auth.styles';
import { LoginAuthStep } from '@auth/pages/enums';
import { Button } from '@components/Button';
import { TotpCodeInputRHF } from '@components/TotpCodeInput/TotpCodeInputRHF';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ButtonVariant, ValidationErrorKeys } from '@utils/enums';
import { LoginMFAPageHeader } from '../Headers/LoginMFAPageHeader';
import { IAuthFormLoginMFA, ILoginMFA } from './types';

export const AuthFormLoginMFA = ({
  accessTokenUserPass,
  handleStepChange,
  hasHeader = true,
  isSkippable = false,
}: IAuthFormLoginMFA) => {
  const dispatch = useDispatch();
  const { translate } = useI18n();

  const initialValues = { totp: '' };
  const schemaShape = {
    totp: Yup.string()
      .required(translate(ValidationErrorKeys.Required))
      .length(6, translate(ValidationErrorKeys.InvalidTOTP)),
  };
  const methods = useForm({
    defaultValues: initialValues,
    mode: 'onBlur',
    resolver: yupResolver(Yup.object().shape(schemaShape)),
  });

  const onSubmit = useCallback(
    async (values: ILoginMFA) => {
      try {
        const {
          data: { access: accessTokenMFA },
        } = await loginMFA(values.totp);
        dispatch(
          actions.setAuthTokenAndIsAwaitingMfaAuth(accessTokenMFA, false),
        );
      } catch {
        methods.setError('root', {
          message: translate(ValidationErrorKeys.IncorrectTotp),
        });
      }
    },
    [dispatch, translate, methods],
  );

  const handleBack = useCallback(() => {
    handleStepChange({ step: LoginAuthStep.Step1LoginPage });
  }, [handleStepChange]);

  const handleSkipMfa = useCallback(() => {
    dispatch(
      actions.setAuthTokenAndIsAwaitingMfaAuth(accessTokenUserPass, false),
    );
  }, [accessTokenUserPass, dispatch]);

  return (
    <>
      {hasHeader ? <LoginMFAPageHeader translate={translate} /> : null}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CenteringWrapper isAuthCode={true}>
            <TotpCodeInputRHF
              error={methods.formState.errors?.root?.message}
              label={translate('OPS_PORTAL.LABEL.AUTHENTICATION_CODE')}
              name={'totp'}
            />
          </CenteringWrapper>
          <AuthButtonWrapper padding={'0 20px'}>
            <ButtonWrapper>
              <Button onClick={handleBack}>
                {translate('OPS_PORTAL.BUTTON.BACK')}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <SpinnerButton
                disabled={methods.formState.isSubmitting}
                id="kt_login_signin_submit"
                type="submit"
                variant={ButtonVariant.Primary}
              >
                <span>{translate('AUTH.SIGN_IN')}</span>
                {methods.formState.isSubmitting ? <Spinner /> : null}
              </SpinnerButton>
            </ButtonWrapper>
          </AuthButtonWrapper>
          <AuthButtonWrapper justifyContent="flex-end" padding="17px 17px">
            {isSkippable ? (
              <ButtonWrapper>
                <Button onClick={handleSkipMfa}>
                  {translate('OPS_PORTAL.BUTTON.SKIP_TO_LOGIN')}
                </Button>
              </ButtonWrapper>
            ) : null}
          </AuthButtonWrapper>
        </form>
      </FormProvider>
    </>
  );
};
