import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormValuesHandler, IAddressValues } from '../types';

interface IFormObserverProps {
  onChange: FormValuesHandler;
}

export const FormObserver = ({ onChange }: IFormObserverProps) => {
  const { dirty, touched, values } = useFormikContext<IAddressValues>();
  const isFormTouched = Object.keys(touched).length > 0;
  useEffect(() => {
    if (dirty || isFormTouched) {
      onChange(values);
    }
  }, [values, dirty, isFormTouched, onChange]);

  return null;
};
