import { isAxiosError } from 'axios';
import { ValidationErrorKeys } from '@utils/enums';

const resetPasswordErrorMapping = {
  'Password was too similar to last four passwords':
    ValidationErrorKeys.PasswordSimilarToLastFour,
};

export const getRequestErrorKey = (error: unknown) => {
  const mappedKey =
    isAxiosError(error) &&
    resetPasswordErrorMapping[
      error.response?.data?.error as keyof typeof resetPasswordErrorMapping
    ];

  return mappedKey || ValidationErrorKeys.InvalidLogin;
};
