export const getSecretFromTotpUrl = (totpUrl: string): string => {
  if (!totpUrl.startsWith('otpauth://')) {
    return '';
  }

  try {
    const url = new URL(totpUrl.replace('otpauth://', 'http://'));
    return url.searchParams.get('secret') ?? '';
  } catch (error) {
    return '';
  }
};
