import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropdownRHF } from '@components/Dropdown';
import { FileUpload } from '@components/FileUpload';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ValidationErrorKeys } from '@utils/enums';
import {
  AcceptedFiles,
  IUploadFileRequest,
  IUseDocumentTypeReturn,
} from './types';

export interface IFileUploadModalContent {
  acceptedFiles: AcceptedFiles;
  documentTypeProps: IUseDocumentTypeReturn;
  formId: string;
  handleFileUpload: (data: IUploadFileRequest) => void;
}

export const FileUploadModalContent = ({
  acceptedFiles,
  documentTypeProps,
  formId,
  handleFileUpload,
}: IFileUploadModalContent) => {
  const { translate } = useI18n();
  const { documentType, documentTypeOptions, handleChangeDocumentType } =
    documentTypeProps;
  const { description } = documentType ?? {};

  const validationSchema = Yup.object({
    documentType: Yup.string().required(
      translate(ValidationErrorKeys.Required),
    ),
    file: Yup.mixed<File[]>()
      .test('fileLength', translate(ValidationErrorKeys.Required), value =>
        Boolean(value && value.length > 0),
      )
      .required(translate(ValidationErrorKeys.Required)),
  });

  const methods = useForm({
    defaultValues: {
      file: [],
      documentType: documentTypeOptions[0]?.id ?? '',
    },
    resolver: yupResolver(validationSchema),
  });

  const acceptedFilesString = acceptedFiles.join(', ');
  const fileSelectorLabel = `${translate(
    'LABEL.CHOOSE_FILE',
  )} (${acceptedFilesString})`;

  return (
    <FormProvider {...methods}>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        id={formId}
        onSubmit={methods.handleSubmit(handleFileUpload)}
      >
        {description ? (
          <p className="text-danger">
            <strong>{translate(description)}</strong>
          </p>
        ) : null}

        <DropdownRHF
          id="documentType"
          label={translate('TITLE.DOCUMENT_TYPE')}
          name="documentType"
          onChange={handleChangeDocumentType}
          options={documentTypeOptions}
        />

        <FileUpload
          accept={acceptedFilesString}
          id="file"
          label={fileSelectorLabel}
          maximumFileSizeMb={40}
          multiple={false}
          name="file"
        />
      </form>
    </FormProvider>
  );
};
