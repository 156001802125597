import { useGetFilters } from '@components/TableV2/hooks/useGetFilters';
import { IFilterSettings } from '@components/TableV2/types';
import { Translate } from '@hooks/useI18n/types';
import { FilterTypes, TableNames } from '@utils/constants';
import { IAggregateAgencyAssignmentsFilterValues } from '../../types';

interface IUseGetAggregatedAgencyAssignmentsFilters {
  initialDefaultValues: IAggregateAgencyAssignmentsFilterValues;
  translate: Translate;
}

export const useGetAggregatedAgencyAssignmentsFilters = ({
  initialDefaultValues,
  translate,
}: IUseGetAggregatedAgencyAssignmentsFilters): IFilterSettings<IAggregateAgencyAssignmentsFilterValues> => {
  const { filterHandler, filterValues, resetFiltersHandler } = useGetFilters(
    TableNames.AGENCY_ASSIGMENTS,
    initialDefaultValues,
  );

  return {
    filters: [
      {
        accessorKey: 'assignmentStartDateFrom',
        from: {
          id: 'assignmentStartDateFrom',
          label: translate('LABEL.START_DATE'),
          value: filterValues.assignmentStartDateFrom ?? '',
        },
        id: 'agencyAssignmentDateFilter',
        to: {
          id: 'assignmentStartDateTo',
          label: translate('LABEL.END_DATE'),
          value: filterValues.assignmentStartDateTo ?? '',
        },
        type: FilterTypes.DATE_RANGE,
      },
    ],
    filterHandler,
    initialDefaultValues,
    resetFiltersHandler,
    filterValues,
  };
};
