import { useMemo, useRef } from 'react';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ClientsideTable } from '@components/Table/ClientsideTable';
import { ITableRefObject } from '@components/Table/types';
import { useI18n } from '@hooks/useI18n/useI18n';
import { TableNames } from '@utils/constants';
import { getClawbacksTableColumns } from './getClawbacksTableColumns';
import { mapClawbacksTableData } from './mapClawbacksTableData';
import { useGetClawbackData } from './queries/useGetClawbackData';
import { IClawbacksTableColumn } from './types';

export const Clawbacks = () => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const refToDataTable = useRef<ITableRefObject<IClawbacksTableColumn>>(null);

  const { data, error, isLoading } = useGetClawbackData();

  const mappedData = mapClawbacksTableData({
    data,
    translate,
    getLocaleDate,
    getLocaleCurrencyValue,
  });

  const columns = useMemo(
    () =>
      getClawbacksTableColumns({
        translate,
      }),
    [translate],
  );

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <Card title={translate('OPS_PORTAL.TITLE.CLAWBACKS')}>
        <ClientsideTable
          columns={columns}
          columnVisibility={{
            hiddenContractExternalUuid: false,
            hiddenMerchantUuid: false,
          }}
          data={mappedData}
          enableRowSelection={true} // Note: can only be assumed as a hack, needed for hidden columns to work
          ref={refToDataTable}
          tableName={TableNames.CLAWBACKS}
        />
      </Card>
    </QueryStateRouter>
  );
};
