import { useEffect, useState } from 'react';
import {
  LimitOffsetPaginationParams,
  LimitOffsetPaginationModel,
} from '@components/TableV2/types';
import { getLimitAndOffset } from '@components/TableV2/utils';

interface IUseLimitOffsetPagination {
  defaultPageSize?: number;
  isClientsidePagination?: boolean;
}

export const useLimitOffsetPagination = ({
  defaultPageSize = 10,
  isClientsidePagination = false,
}: IUseLimitOffsetPagination = {}) => {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pageNumber, setPageNumber] = useState(0);
  const [paginationParams, setPaginationParams] =
    useState<LimitOffsetPaginationParams>(
      getLimitAndOffset({
        pageNumber,
        pageSize,
      }),
    );
  const handlePagination = ({
    page,
    pageSize: size,
  }: LimitOffsetPaginationModel) => {
    setPageSize(size);
    setPageNumber(page);
  };

  useEffect(() => {
    setPaginationParams(
      getLimitAndOffset({
        pageNumber,
        pageSize,
      }),
    );
  }, [pageSize, pageNumber]);

  return {
    paginationParams,
    handlePagination,
    isClientsidePagination,
    pageNumber,
    pageSize,
  };
};
