import { Dd, Dl, Dt } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IFormattedAddress } from '@schemas/consumerPortal/types/addressData';

interface IAddressChangeSummaryProps {
  initialFormattedAddress?: IFormattedAddress;
  newFormattedAddress?: IFormattedAddress;
}

export const AddressChangeSummary = ({
  initialFormattedAddress,
  newFormattedAddress,
}: IAddressChangeSummaryProps) => {
  const { translate } = useI18n();

  return (
    <Dl>
      <Dt id="currentAddress">{translate('LABEL.CURRENT_ADDRESS')}</Dt>
      <Dd aria-labelledby="currentAddress">{initialFormattedAddress ?? ''}</Dd>
      <Dt id="newAddress">{translate('LABEL.NEW_ADDRESS')}</Dt>
      <Dd aria-labelledby="newAddress">{newFormattedAddress ?? ''}</Dd>
    </Dl>
  );
};
