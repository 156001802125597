export enum CustomComponentTypes {
  PostalCode = 'postal_code',
  PostalTown = 'postal_town',
  Route = 'route',
  SecondLine = 'second_line',
  StreetNumber = 'street_number',
}

export enum RawAddressFormatType {
  Google = 'google',
  Manual = 'manual',
  SwissPostal = 'swiss_postal',
}
