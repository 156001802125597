import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError/usePortalError';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { CreateClawbackForm } from './CreateClawbackForm';

import {
  ICreateClawbackModalProps,
  IMutationResponse,
  IPayload,
  ISubmitHandlerProps,
} from './types';

export const useCreateClawbackModal = (
  props: ICreateClawbackModalProps,
): Modal => {
  const { advanceRemainder, contractUuid, invalidateMonetaData } = props;
  const { translate } = useI18n();
  const { message } = useToast(translate);
  const { handleAxiosError } = usePortalError();

  const modal = useModal({
    sharedProps: props,
    modalName: 'createClawbackModal',
    buttonTranslationKey: 'OPS_PORTAL.TITLE.CREATE_CLAWBACK',
    permissions: ['ops_portal.can_create_merchant_clawbacks'],
  });
  const { closeModal, formStatusMessage } = modal;

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation<
    IMutationResponse,
    AxiosError<IError>,
    IPayload
  >(
    data => {
      const endpoint = Endpoints.CreateClawback;
      return postDataToEndpoint({ endpoint, requestData: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryKeys.CONTRACT_DETAILS_DATA,
          contractUuid,
        ]);
        queryClient.invalidateQueries([
          QueryKeys.MONETA_BALANCE_DATA,
          contractUuid,
        ]);
        message.success('OPS_PORTAL.STATUS.CLAWBACK_SUCCESS');
        invalidateMonetaData?.();
        closeModal();
      },
      onError: error => {
        handleAxiosError(error, 'OPS_PORTAL.STATUS.CLAWBACK_FAILED');
        closeModal();
      },
    },
  );

  const { amount: maxAmount, currency } = advanceRemainder ?? {};

  const handleSubmit = useCallback(
    (data: ISubmitHandlerProps) => {
      if (contractUuid) {
        mutate({ contract_uuid: contractUuid, amount: data.amount });
      }
    },
    [contractUuid, mutate],
  );

  const formID = 'create-clawback';

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={formStatusMessage}
      isLoading={isLoading}
    >
      <>
        <p>
          {translate('OPS_PORTAL.PAGE.CLAWBACK.CLAWBACK_MODAL_TEXT', {
            remainingAdvance: maxAmount,
          })}
        </p>
        <CreateClawbackForm
          currency={currency}
          formID={formID}
          handleSubmit={handleSubmit}
          maxAmount={maxAmount}
        />
      </>
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    isFormProcessing: isLoading,
    isLoading,
    modalContent,
    title: translate('OPS_PORTAL.TITLE.CREATE_CLAWBACK'),
  };

  return getModalDetails({ modal, modalProps });
};
