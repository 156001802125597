/* eslint-disable max-len */
import { useCallback } from 'react';
import { TableCursorPaginationV2 } from '@components/TableV2/TableCursorPaginationV2';
import { useI18n } from '@hooks/useI18n/useI18n';
import { TableNames } from '@utils/constants';
import { IHandleAgencyScreenChange } from './AgencyAssignments';
import { useGetAgencyAssignedContractsTableConfig } from './hooks/contractsTable/useGetAgencyAssignedContractsTableConfig';
import { IAgencyAssignedContractsFilterValues } from './types';
import { generateTitle } from './utils/generateTitle';

interface IAgencyAssignedContracts {
  contractsScreenFilters: IAgencyAssignedContractsFilterValues;
  handleAgencyScreenChange: ({
    filters,
    isOnAggregateScreen,
  }: IHandleAgencyScreenChange) => void;
}

export const AgencyAssignedContracts = ({
  contractsScreenFilters,
  handleAgencyScreenChange,
}: IAgencyAssignedContracts) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const {
    columns,
    data: agencyAssignedContractsData,
    error,
    isLoading,
    paginationSettings,
    sortingSettings,
  } = useGetAgencyAssignedContractsTableConfig({
    getLocaleCurrencyValue,
    getLocaleDate,
    contractsScreenFilters,
    translate,
  });

  const handleBackClick = useCallback(() => {
    handleAgencyScreenChange({ isOnAggregateScreen: true });
  }, [handleAgencyScreenChange]);

  const title = generateTitle(contractsScreenFilters, translate);

  return (
    <TableCursorPaginationV2
      buttons={[
        {
          buttonKey: 'goBack',
          clickHandler: handleBackClick,
          text: translate('OPS_PORTAL.BUTTON.GO_BACK'),
        },
      ]}
      columns={columns}
      count={agencyAssignedContractsData?.count ?? 10}
      data={agencyAssignedContractsData?.results}
      error={error}
      isLoading={isLoading}
      name={TableNames.AGENCY_ASSIGNED_CONTRACTS}
      paginationSettings={paginationSettings}
      sortingSettings={sortingSettings}
      title={title}
    />
  );
};
