import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { TableCursorPaginationV2 } from '@components/TableV2/TableCursorPaginationV2';
import { useCursorPagination } from '@hooks/useCursorPagination/useCursorPagination';
import { useI18n } from '@hooks/useI18n';
import { useTableSorting } from '@hooks/useTableSorting/useTableSorting';

import { IOpsPortalLateFees } from '@schemas/opsPortal/types/lateFees';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { useGetLateFeesTableColumns } from './useLateFeesTableColumns';

interface ILateFeesTable {
  contractUuid: string | undefined;
}

export const LateFeesTable = ({ contractUuid }: ILateFeesTable) => {
  const { translate } = useI18n();
  const paginationSettings = useCursorPagination();

  const {
    data: lateFeesData,
    error: lateFeesError,
    isLoading: isLateFeesLoading,
  } = useQuery<IOpsPortalLateFees, AxiosError>(
    [QueryKeys.LATE_FEES, contractUuid],
    () => fetchDataFromEndpoint(`${Endpoints.FetchLateFees}/${contractUuid}`),
    {
      onSuccess: responseData => {
        paginationSettings.setNextCursor(responseData.next_cursor ?? '');
        paginationSettings.setPrevCursor(responseData.previous_cursor ?? '');
      },
      enabled: Boolean(contractUuid),
      cacheTime: 0,
    },
  );

  const columns = useGetLateFeesTableColumns();
  const sortingSettings = useTableSorting();

  return (
    <TableCursorPaginationV2
      columns={columns}
      count={lateFeesData?.count ?? 0}
      data={lateFeesData?.results ?? []}
      error={lateFeesError}
      isLoading={isLateFeesLoading}
      name="LateFees"
      paginationSettings={paginationSettings}
      sortingSettings={sortingSettings}
      title={translate('OPS_PORTAL.LABEL.LATE_FEES')}
      wrapTableInCard={false}
    />
  );
};
