import { Translate } from '@hooks/useI18n';
import { FilterTypes, missingValuePlaceholder } from '@utils/constants';

interface IGetClawabackFilterProps {
  translate: Translate;
}

export const getClawbackFilters = ({ translate }: IGetClawabackFilterProps) => {
  const merchantStatusDropdown = {
    accessorKey: 'merchantStatus',
    id: 'merchantStatus',
    label: translate('OPS_PORTAL.LABEL.MERCHANT_STATUS'),
    options: [
      { id: '', name: missingValuePlaceholder },
      { id: 'ACTIVE', name: translate('OPS_PORTAL.LABEL.ACTIVE') },
      { id: 'BLOCKED', name: translate('OPS_PORTAL.LABEL.BLOCKED') },
      { id: 'CANCELLED', name: translate('OPS_PORTAL.LABEL.CANCELLED') },
      { id: 'INACTIVE', name: translate('OPS_PORTAL.LABEL.INACTIVE') },
      { id: 'ONBOARDED', name: translate('OPS_PORTAL.LABEL.ONBOARDED') },
      { id: 'ONBOARDING', name: translate('OPS_PORTAL.LABEL.ONBOARDING') },
    ],
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  const settledStatusDropdown = {
    accessorKey: 'paymentStatus',
    id: 'paymentStatus',
    label: translate('OPS_PORTAL.LABEL.SETTLED_STATUS'),
    options: [
      { id: '', name: missingValuePlaceholder },
      {
        id: 'Settled',
        name: translate('OPS_PORTAL.LABEL.SETTLED'),
      },
      {
        id: 'Unsettled',
        name: translate('OPS_PORTAL.LABEL.UNSETTLED'),
      },
    ],
    type: FilterTypes.DROPDOWN,
    value: '',
  };

  return {
    merchantStatusDropdown,
    settledStatusDropdown,
  };
};
