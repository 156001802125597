import { styled } from '@mui/material/styles';
import { Button } from '@components/Button';

const StyledButton = styled(Button)`
  color: var(--heylight-primary);
  text-decoration: underline;
  background-color: transparent;
  width: 100%;
  display: block;
  text-align: left;
  &:hover {
    color: var(--heylight-hover);
    text-decoration: underline !important;
    text-underline-offset: 0.25rem;
  }
`;

interface IClickableCell {
  onClick: () => void;
  text: string | number;
}

export const ClickableCell = ({ onClick, text }: IClickableCell) => (
  <StyledButton onClick={onClick}>{text}</StyledButton>
);
