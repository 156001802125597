import { IAmountObject } from '@schemas/opsPortal/types/contractDetails';

export const getAdvanceRemainder = (
  totalBalance?: IAmountObject,
  totalLateFees?: IAmountObject,
  totalClawback?: IAmountObject,
) => {
  if (!totalBalance || !totalLateFees || !totalClawback) {
    return undefined;
  }

  const advanceRemainderAmount = (
    parseFloat(totalBalance.amount) -
    parseFloat(totalLateFees.amount) -
    parseFloat(totalClawback.amount)
  ).toFixed(2);

  return {
    amount: advanceRemainderAmount,
    currency: totalBalance?.currency,
  } as IAmountObject;
};
