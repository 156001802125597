import { ValueOf } from '@appTypes';
import { ContractStatuses } from '@utils/constants';

interface ICanCreateClawback {
  contractStatus: ValueOf<typeof ContractStatuses>;
  hasAdvanceRemainder?: boolean;
  isCreditType: boolean;
}

export const canContractCreateClawback = ({
  contractStatus,
  hasAdvanceRemainder,
  isCreditType,
}: ICanCreateClawback) => {
  const isContractActive = contractStatus === ContractStatuses.ACTIVE;
  const isContractTerminated =
    contractStatus === ContractStatuses.TERMINATED ||
    contractStatus === ContractStatuses.TERMINATED_AND_PAID;

  return (
    hasAdvanceRemainder &&
    !isCreditType &&
    (isContractActive || isContractTerminated)
  );
};
