import { useCallback, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import * as Yup from 'yup';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import { EnumValueAsKey } from '@appTypes';
import { Spinner } from '@auth/pages/Auth.styles';
import { Button } from '@components/Button';
import { Card } from '@components/Card';
import { DropdownRHF } from '@components/Dropdown';
import { FileUpload } from '@components/FileUpload';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { selectPermissions } from '@selectors/index';
import { ValidationErrorKeys, ButtonVariant, Endpoints } from '@utils/enums';
import { postFormDataToEndpoint } from '@utils/postDataToEndpoint';
import { validatePermissions } from '@utils/validatePermissions';
import { BulkUploadType } from './enums';
import { IBulkUploadRequest, IUploadType } from './types.d';

const BulkUploadContainer = styled('div')({
  maxWidth: '500px',
});

const UploadTypes: EnumValueAsKey<BulkUploadType, IUploadType> = {
  bank_account_transactions: {
    accept: '.xml',
    endpoint: Endpoints.UploadBankAccountTransactions,
    name: 'OPS_PORTAL.TITLE.BANK_ACCOUNT_TRANSACTIONS_UPLOAD',
    scopes: ['ops_portal.can_upload_bank_account_statements'],
  },
  manual_payment: {
    accept: 'text/csv',
    endpoint: Endpoints.UploadManualPaymentsCsv,
    name: 'OPS_PORTAL.TITLE.MANUAL_PAYMENT',
    scopes: ['ops_portal.can_upload_csv_manual_payment'],
  },
};

export const BulkUpload = () => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const permissions = useSelector(selectPermissions);
  const formId = 'BulkUpload';

  const bulkUploadOptions = useMemo(
    () =>
      Object.entries(UploadTypes)
        .filter(entry =>
          validatePermissions({ permissions, scopes: entry[1].scopes }),
        )
        .map(([key, value]) => ({
          id: key,
          name: translate(value.name),
        })),
    [permissions, translate],
  );

  const validationSchema = Yup.object({
    file: Yup.mixed<File[]>()
      .test('fileLength', translate(ValidationErrorKeys.Required), value =>
        Boolean(value && value.length > 0),
      )
      .required(translate(ValidationErrorKeys.Required)),
    bulk_upload_type: Yup.string().required(
      translate(ValidationErrorKeys.Required),
    ),
  });

  const methods = useForm({
    defaultValues: {
      bulk_upload_type: bulkUploadOptions.length
        ? bulkUploadOptions[0].id
        : null,
      file: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const { setValue, watch } = methods;

  const { isLoading, mutate } = useMutation<
    unknown,
    AxiosError<IError>,
    IBulkUploadRequest
  >(
    requestData => {
      const formData = new FormData();
      formData.append('file', requestData.file[0], requestData.file[0].name);

      return postFormDataToEndpoint({
        endpoint: UploadTypes[requestData.bulk_upload_type].endpoint,
        requestData: formData,
      });
    },
    {
      onSuccess: () => {
        message.success('OPS_PORTAL.PAGE.BULK_UPLOAD.SUCCESSFULLY_UPLOADED');
        setValue('file', '');
      },
      onError: error => {
        handleAxiosError(error);
      },
    },
  );

  const handleCsvUpload = useCallback(data => mutate(data), [mutate]);

  const type = watch('bulk_upload_type');
  const accept = UploadTypes[type as BulkUploadType]?.accept;

  return (
    <Card isSubsection={true} title={translate('OPS_PORTAL.TITLE.BULK_UPLOAD')}>
      <FormProvider {...methods}>
        <BulkUploadContainer>
          <form
            className="form fv-plugins-bootstrap fv-plugins-framework"
            id={formId}
            onSubmit={methods.handleSubmit(handleCsvUpload)}
          >
            <DropdownRHF
              className="w-200"
              id="bulk_upload_type"
              label={translate('OPS_PORTAL.TITLE.BULK_UPLOAD_TYPE')}
              name="bulk_upload_type"
              options={bulkUploadOptions}
            />
            <FileUpload
              accept={accept}
              id="file"
              label={`${translate('OPS_PORTAL.TITLE.CHOOSE_FILE')} (${accept})`}
              multiple={false}
              name="file"
            />
            <Button
              disabled={isLoading}
              form={formId}
              type="submit"
              variant={ButtonVariant.Primary}
            >
              {translate('OPS_PORTAL.BUTTON.UPLOAD')}
              {isLoading ? <Spinner extraClassName="pr-5" /> : null}
            </Button>
          </form>
        </BulkUploadContainer>
      </FormProvider>
    </Card>
  );
};
