import { ISwissPostLabels } from '@heidi-pay/heidi-component-library/components/SwissPostAddress';
import { useI18n } from '@hooks/useI18n';

export const useGetAddressSwissPostLabels = (): ISwissPostLabels => {
  const { translate } = useI18n();
  const getFieldLabels = (fieldName: string) => ({
    label: translate(`LABEL.SWISS_POST_ADDRESS_${fieldName}_LABEL`),
    errorMessage: translate(`LABEL.SWISS_POST_ADDRESS_${fieldName}_INVALID`),
    required: translate(`LABEL.SWISS_POST_ADDRESS_${fieldName}_REQUIRED`),
  });

  return {
    expandCheckbox: translate('LABEL.SWISS_POST_ADDRESS_EXPAND_CHECKBOX'),
    addressTitle: translate('TITLE.SWISS_POST_ADDRESS'),
    enterZipCode: translate('LABEL.SWISS_POST_ADDRESS_ENTER_ZIP_CODE'),
    field: {
      country: getFieldLabels('COUNTRY'),
      city: getFieldLabels('CITY'),
      province: getFieldLabels('PROVINCE'),
      streetName: getFieldLabels('STREET_NAME'),
      streetNumber: getFieldLabels('STREET_NUMBER'),
      zipCode: getFieldLabels('ZIP_CODE'),
    },
  };
};
