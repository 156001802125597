import { createColumnHelper } from '@tanstack/react-table';
import { ReactTableColumns } from '@appTypes';
import { Link } from '@components/Common.styles';
import { Translate } from '@hooks/useI18n';
import { filterDateRange } from '@utils/filterDateRange/filterDateRange';
import { IClawbacksTableColumn } from './types';

interface IGetDocumentsTableColumns {
  translate: Translate;
}

export const getClawbacksTableColumns = ({
  translate,
}: IGetDocumentsTableColumns): ReactTableColumns<IClawbacksTableColumn> => {
  const columnHelper = createColumnHelper<IClawbacksTableColumn>();

  return [
    columnHelper.accessor('merchant', {
      header: translate('OPS_PORTAL.TITLE.MERCHANT'),
      cell: ({ row }) => (
        <Link to={`/merchants/${row.original.hiddenMerchantUuid}`}>
          {row.original.merchant}
        </Link>
      ),
    }),
    columnHelper.accessor('merchantStatus', {
      header: translate('OPS_PORTAL.TITLE.MERCHANT_STATUS'),
    }),
    columnHelper.accessor('amount', {
      header: translate('OPS_PORTAL.TITLE.AMOUNT'),
    }),
    columnHelper.accessor('createdDate', {
      header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
      filterFn: (row, _, filterValue) =>
        filterDateRange({
          accessorKey: 'createdDate',
          filterValue,
          row,
        }),
    }),
    columnHelper.accessor('contract', {
      header: translate('OPS_PORTAL.TITLE.CONTRACT'),
      cell: ({ row }) => (
        <Link to={`/all_contracts/${row.original.hiddenContractExternalUuid}`}>
          {row.original.contract}
        </Link>
      ),
    }),
    columnHelper.accessor('paymentStatus', {
      header: translate('OPS_PORTAL.TITLE.PAYMENT_STATUS'),
      filterFn: (row, _, filterValue) =>
        filterValue !== '' ? row.original.paymentStatus === filterValue : true,
    }),
    columnHelper.accessor('paymentDate', {
      header: translate('OPS_PORTAL.TITLE.PAYMENT_DATE'),
    }),
    columnHelper.accessor('paymentReference', {
      header: translate('OPS_PORTAL.TITLE.PAYMENT_REFERENCE'),
    }),
    columnHelper.accessor('hiddenMerchantUuid', {}),
    columnHelper.accessor('hiddenContractExternalUuid', {}),
  ];
};
