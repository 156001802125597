import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AgencyAssignedContracts } from './AgencyAssignedContracts';
import { AggregatedAgencyAssignments } from './AggregatedAgencyAssignments';
import { intialAgencyContractsFilterValues } from './constants';
import { IAgencyAssignedContractsFilterValues } from './types';

export interface IHandleAgencyScreenChange {
  filters?: IAgencyAssignedContractsFilterValues;
  isOnAggregateScreen: boolean;
}

export const AgencyAssignments = () => {
  const [isMainScreen, setIsMainScreen] = useState(true);
  const [contractsScreenFilters, setContractScreenFilters] =
    useState<IAgencyAssignedContractsFilterValues>(
      intialAgencyContractsFilterValues,
    );
  const [searchParams, setSearchParams] = useSearchParams();
  const [mainScreenParams, setMainScreenParams] = useState('');

  const handleAgencyScreenChange = useCallback(
    ({ filters, isOnAggregateScreen }: IHandleAgencyScreenChange) => {
      if (!isOnAggregateScreen) {
        setMainScreenParams(searchParams.toString());
        setSearchParams('');
        if (filters) {
          setContractScreenFilters(filters);
        }
      } else {
        setSearchParams(mainScreenParams.toString());
      }

      setIsMainScreen(isOnAggregateScreen);
    },
    [
      mainScreenParams,
      searchParams,
      setSearchParams,
      setContractScreenFilters,
      setIsMainScreen,
    ],
  );

  return isMainScreen ? (
    <AggregatedAgencyAssignments
      handleAgencyScreenChange={handleAgencyScreenChange}
    />
  ) : (
    <AgencyAssignedContracts
      contractsScreenFilters={contractsScreenFilters}
      handleAgencyScreenChange={handleAgencyScreenChange}
    />
  );
};
