import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import * as Yup from 'yup';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DatePickerRHF } from '@components/DatePicker';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError';
import { useCertificatesOfInterestTab } from '@pages/ContractDetails/hooks';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';

const formatDate = (date: string) => format(new Date(date), 'yyyy-MM-dd');

interface IUseAddCertificateOfInterestModal extends ISharedModalProps {
  contractData?: IContractDetails;
  externalContractUUID?: string;
}

interface IAddCertificateOfInterestForm {
  end_date: string;
  start_date: string;
}

export const useAddCertificateOfInterestModal = (
  props: IUseAddCertificateOfInterestModal,
): Modal => {
  const { contractData, externalContractUUID } = props;
  const formID = 'addCertificateOfInterest';
  const { translate } = useI18n();
  const { message } = useToast();
  const { handleAxiosError } = usePortalError();
  const queryClient = useQueryClient();

  const modal = useModal({
    sharedProps: props,
    buttonTranslationKey: 'OPS_PORTAL.TITLE.ADD_CERTIFICATE_OF_INTEREST',
    modalName: 'addCertificateOfInterestModal',
    permissions: ['ops_portal.generate_certificate_of_interests'],
  });
  const { closeModal } = modal;

  const schema = Yup.object().shape({
    start_date: Yup.date().required(
      'OPS_PORTAL.VALIDATION.START_DATE_REQUIRED',
    ),
    end_date: Yup.date().required('OPS_PORTAL.VALIDATION.END_DATE_REQUIRED'),
  });

  const { queryKey } = useCertificatesOfInterestTab({ externalContractUUID });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset } = methods;

  const { isLoading, mutate } = useMutation<
    void,
    AxiosError<IError>,
    IAddCertificateOfInterestForm
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: `${Endpoints.GenerateCertificateOfInterest}/${externalContractUUID}`,
        requestData: {
          ...requestData,
          start_date: formatDate(requestData.start_date),
          end_date: formatDate(requestData.end_date),
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        message.success(
          translate('OPS_PORTAL.SUCCESSFULLY_GENERATED_CERTIFICATE'),
        );
        closeModal();
      },
      onError: error =>
        handleAxiosError(error, 'OPS_PORTAL.ERROR_GENERATING_CERTIFICATE'),
    },
  );

  const handleGenerateCertificate = useCallback(data => mutate(data), [mutate]);

  useEffect(() => {
    if (contractData?.finalised_at) {
      const finalisedAt = formatDate(contractData.finalised_at);
      reset({
        start_date: finalisedAt,
      });
    }
  }, [contractData, reset]);

  const modalProps = {
    allowoverflow: true,
    modalContent: (
      <FormProvider {...methods}>
        <form id={formID} onSubmit={handleSubmit(handleGenerateCertificate)}>
          <DatePickerRHF
            isClearable={false}
            label={translate('LABEL.START_DATE')}
            name="start_date"
            showPopperArrow={false}
          />
          <DatePickerRHF
            isClearable={false}
            label={translate('LABEL.END_DATE')}
            name="end_date"
            showPopperArrow={false}
          />
        </form>
      </FormProvider>
    ),
    submitText: translate('BUTTON.SUBMIT'),
    title: translate('OPS_PORTAL.TITLE.ADD_CERTIFICATE_OF_INTEREST'),
    formID,
    isLoading,
  };

  return getModalDetails({ modal, modalProps });
};
