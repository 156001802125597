import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHasPermission } from '@hooks/useHasPermission';
import { FeatureFlag } from '@redux/featureFlag/enums';
import { selectFeatureFlag } from '@redux/featureFlag/selectors';
import { RootState } from '@redux/types';
import { getEnv } from '@utils/getEnv';
import { IUseFeatureFlags } from './types';

export const useFeatureFlags = (): IUseFeatureFlags => {
  const canViewBetaFeatures = useHasPermission([
    'ops_portal.can_view_beta_features',
  ]);

  const isExperimentalUIEnabled =
    useSelector((state: RootState) =>
      selectFeatureFlag(state, FeatureFlag.ExperimentalUI),
    ) && canViewBetaFeatures;

  const [isProdCheck, setIsProdCheck] = useState(true);
  useEffect(() => {
    // React will complain that you can't perform a React state update on an unmounted component.
    // There is no memory leak here, though. React just can't see into the useEffect, so it
    // shows the warning, just in case. This will go away when we upgrade to React 18.
    const getEnvCheck = async () => {
      const { isProd } = await getEnv();
      setIsProdCheck(isProd);
    };
    getEnvCheck();
  }, []);

  return {
    isProdFeatureFlag: isProdCheck,
    isExperimentalUIEnabled,
    isMfaCapabilityEnabled: true,
  };
};
