/* eslint-disable max-nested-callbacks */
import { IOpsPortalAggregateAgencyAssignments } from '@schemas/opsPortal/types/aggregateAgencyAssignments';
import { allAgencyNames, allBucketNames } from '../constants';
import { IAgencyAssignmentCountData } from '../types';

export const getTotalBucketCountsPerDatePerAgency = (
  data: IOpsPortalAggregateAgencyAssignments,
): IAgencyAssignmentCountData[] => {
  const results: IAgencyAssignmentCountData[] = [];

  const allDates = new Set<string>();
  Object.values(data).forEach(dates => {
    Object.keys(dates).forEach(date => allDates.add(date));
  });

  allAgencyNames.forEach(agency => {
    allBucketNames.forEach(bucket => {
      const assignmentCounts: Record<string, number> = {};
      allDates.forEach(date => {
        assignmentCounts[date] = 0;
      });

      if (bucket in data) {
        const bucketData = data[bucket];

        if (bucketData) {
          // Ensure bucketData is not undefined
          Object.entries(bucketData).forEach(([date, assignments]) => {
            assignmentCounts[date] = assignments[agency] || 0;
          });
        }
      }

      results.push({
        agency,
        bucket,
        assignmentCounts,
      });
    });
  });

  return results;
};
