import { useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { FormGroup, InputText, Label } from '@components/Common.styles';
import { ValidationError } from '@components/ValidationError';
import { useI18n } from '@hooks/useI18n/useI18n';

interface ITextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  endAdornment?: React.ReactNode;
  label: string;
  name: string;
  readOnly?: boolean;
}

const InputWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const EndAdornmentWrapper = styled(Box)({
  marginLeft: '0.5rem',
});

export const TextFieldRHF = ({
  containerClassName,
  endAdornment,
  label,
  name,
  readOnly,
  ...props
}: ITextFieldProps) => {
  const { translate } = useI18n();
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const { className: propsClassName, ...remainingProps } = props;
  const error = errors[name]?.message;

  const inputElement = (
    <InputText
      extraClassName={propsClassName}
      id={name}
      readOnly={readOnly}
      {...register(name)}
      {...remainingProps}
      style={{ flex: 1 }}
    />
  );

  return (
    <FormGroup extraClassName={containerClassName}>
      <Label htmlFor={name}>
        {label + (readOnly ? ` (${translate('LABEL.CANNOT_BE_EDITED')})` : '')}
      </Label>

      {endAdornment ? (
        <InputWrapper>
          {inputElement}
          <EndAdornmentWrapper>{endAdornment}</EndAdornmentWrapper>
        </InputWrapper>
      ) : (
        inputElement
      )}

      {error ? <ValidationError error={error} /> : null}
    </FormGroup>
  );
};
