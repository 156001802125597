import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { GetLocaleCurrencyValue, GetLocaleDate } from '@hooks/useI18n';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { IPaymentScheduleTableData } from '../../utils/types';

interface IMapPaymentDataProps {
  data?: IOpsPortalMonetaContract;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
}

export const mapPaymentScheduleData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
}: IMapPaymentDataProps): IPaymentScheduleTableData[] =>
  data?.balances
    ?.filter(
      ({
        amount_due: amountDue,
        amount_paid: amountPaid,
        latest_schedule: latestSchedule,
      }) =>
        (Number(amountDue) !== 0 || Number(amountPaid) !== 0) && latestSchedule,
    )
    .map(dataRow => {
      const {
        amount_due: amountDue,
        amount_paid: amountPaid,
        arrears,
        is_minor_arrears: isMinorArrears,
        payment_date: paymentDate,
        payment_type: paymentType,
      } = dataRow;

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const givenDate = new Date(paymentDate);
      givenDate.setHours(0, 0, 0, 0);

      const { currency } = data;
      const amountDueString =
        amountDue &&
        getLocaleCurrencyValue({
          currency,
          value: amountDue,
        });

      const amountPaidString =
        amountPaid &&
        getLocaleCurrencyValue({
          currency,
          value: amountPaid,
        });

      const arrearsNumber = parseFloat(arrears);
      const arrearsString =
        arrears &&
        getLocaleCurrencyValue({
          currency,
          value: arrears,
        });

      const minorArrearsString = `${arrearsString}${
        isMinorArrears ? ' (m.a.)' : ''
      }`;

      const isFuture = currentDate < givenDate;
      const focused = !isFuture || arrearsNumber <= 0;
      const paymentScheduleData =
        addPlaceholderForEmptyValues<IPaymentScheduleTableData>({
          amountDue: amountDueString,
          amountPaid: amountPaidString,
          arrears: focused ? minorArrearsString : null,
          paymentDate: getLocaleDate({ date: paymentDate }),
          paymentType,
        });
      return { ...paymentScheduleData, focused };
    }) ?? [];
