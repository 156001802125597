import {
  TableColumns,
  createTableColumnsV2,
} from '@components/TableV2/helpers/createTableColumnsV2';
import {
  IAgencyAssignmentCountData,
  IUseGetAggregatedAgencyAssignmentsColumns,
} from '../../types';
import { generateColumnsByWeeks } from '../../utils/generateColumnsByWeeks';
import { getBucketColumn } from '../../utils/getBucketColumn';

export const useGetAggregatedAgencyAssignmentsColumnsWeeks = ({
  dates,
  handleAgencyScreenChange,
  translate,
}: IUseGetAggregatedAgencyAssignmentsColumns) => {
  const datesColumns = generateColumnsByWeeks(dates, handleAgencyScreenChange);

  const columns: TableColumns<IAgencyAssignmentCountData> = [
    getBucketColumn(translate),
    ...datesColumns,
  ];

  return createTableColumnsV2({ columns });
};
