import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useConfirm } from '@hooks/useConfirm';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError';
import { IOpsPortalApplicationDetails } from '@schemas/opsPortal/types/applicationDetails';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { EmailUserModalContent } from './EmailUserModalContent';
import { useGetEmailTemplates } from './queries/useGetEmailTemplates';
import { IEmailUserModalProps, IHandleSendEmailProps } from './types';
import { useTemplateState } from './useTemplateState';

export const useEmailUserModal = (props: IEmailUserModalProps): Modal => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const { collectionsData, contractData, isInternalOpsUser } = props;

  const modal = useModal({
    sharedProps: props,
    modalName: 'emailUserModal',
    permissions: ['ops_portal.can_send_email_to_consumer'],
    buttonTranslationKey: 'OPS_PORTAL.BUTTON.EMAIL_CUSTOMER',
  });

  const { isTemplatesListLoading, templatesList } = useGetEmailTemplates({
    enabled: modal.isModalShowing,
  });

  // Sadly, default values only work with undefined, and the data can be null.
  const data = contractData ?? {};

  const applicationData = data as IOpsPortalApplicationDetails;
  const contractDetailsData = data as IContractDetails;

  const { currency, external_uuid: externalUUID } = contractDetailsData;
  const { external_contract_uuid: externalContractUUID } = applicationData;

  const arrearsAmount = collectionsData
    ? collectionsData.arrears_amount
    : undefined;

  const uuid = externalUUID ?? externalContractUUID;

  const isInArrears = parseFloat(arrearsAmount?.amount ?? '0') > 0;

  const confirm = useConfirm();

  const actAfterConfirmation = useCallback(
    async (prompt: string, callback: () => void) => {
      if (await confirm?.({ confirmation: prompt })) {
        callback();
      }
    },
    [confirm],
  );

  const {
    handleSelectTemplate,
    isLoading: isSelectedTemplateLoading,
    language,
    selectedTemplate,
    setLanguage,
    setSelectedTemplate,
    setTriggerId,
    triggerId,
  } = useTemplateState(uuid);

  const goBack = useCallback(async () => {
    actAfterConfirmation(
      translate('OPS_PORTAL.NOTIFICATION.CHANGES_WILL_BE_LOST'),
      () => setSelectedTemplate(undefined),
    );
  }, [actAfterConfirmation, setSelectedTemplate, translate]);

  const hideModal = useCallback(() => {
    setLanguage(undefined);
    setTriggerId('');
    setSelectedTemplate(undefined);
    modal.closeModal();
  }, [modal, setLanguage, setSelectedTemplate, setTriggerId]);

  const queryClient = useQueryClient();

  const { isLoading: isSendingEmail, mutate: sendEmail } = useMutation<
    unknown,
    AxiosError<IError>,
    IHandleSendEmailProps
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: Endpoints.SendCustomerEmail,
        requestData,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.COMMUNICATIONS_EVENTS]);
        message.success('OPS_PORTAL.STATUS.EMAIL_SUCCESSFULLY_SENT');
        hideModal();
      },
      onError: error => {
        handleAxiosError(error, 'OPS_PORTAL.STATUS.UNABLE_TO_SEND_EMAIL');
      },
    },
  );

  const handleSendEmail = useCallback(
    async (values: IHandleSendEmailProps) => {
      actAfterConfirmation(
        translate('OPS_PORTAL.PAGE.CONFIRM_SEND_EMAIL'),
        () => {
          const { body_html: bodyHtml, subject, ...templateFields } = values;

          const parameters = Object.keys(templateFields).length
            ? { parameters: { ...templateFields } }
            : {};

          sendEmail({
            body_html: bodyHtml,
            contract_external_uuid: uuid,
            language,
            subject,
            trigger_id: triggerId,
            ...parameters,
          });
        },
      );
    },
    [actAfterConfirmation, language, sendEmail, translate, triggerId, uuid],
  );

  const formID = 'send-customer-email';
  const isLoading = isSelectedTemplateLoading || isTemplatesListLoading;
  const isBusy = isLoading || isSendingEmail;

  const modalContent = (
    <EmailUserModalContent
      currency={currency}
      formID={formID}
      goBack={goBack}
      handleSelectTemplate={handleSelectTemplate}
      handleSendEmail={handleSendEmail}
      isInArrears={isInArrears}
      isInternalOpsUser={Boolean(isInternalOpsUser)}
      language={language}
      selectedTemplate={selectedTemplate}
      templates={templatesList}
      triggerId={triggerId}
    />
  );

  const loadingOverlayText = isLoading ? 'STATUS.LOADING' : undefined;

  const modalProps = {
    formID,
    isFormProcessing: isBusy,
    isLoading: isBusy,
    loadingOverlay: true,
    loadingOverlayText,
    modalContent,
    showFormButtons: !isBusy,
    title: translate('OPS_PORTAL.TITLE.SEND_EMAIL_TO_CUSTOMER'),
  };

  return applicationData || (contractData && collectionsData)
    ? getModalDetails({ modal, modalProps })
    : null;
};
