import { useCallback } from 'react';
import { FieldErrors } from 'react-hook-form';
import { FormError } from '@auth/pages/Auth.styles';
import { TextFieldRHF } from '@components/TextField';
import { AuthField, Field, IAuthLabels } from './types';

interface IAuthFormFields {
  errors: FieldErrors<Record<Field, string>>;
  fields: AuthField[];
  hasSubmitted: boolean;
  labels: IAuthLabels;
  touched: Partial<Record<Field, string>>;
}

export const AuthFormFields = ({
  errors,
  fields,
  hasSubmitted,
  labels,
  touched,
}: IAuthFormFields) => {
  const getInputClasses = useCallback(
    (fieldname: Field) => {
      const touchedClassName = errors[fieldname] ? 'is-invalid' : 'is-valid';
      return touched[fieldname] && hasSubmitted ? touchedClassName : '';
    },
    [errors, hasSubmitted, touched],
  );

  return (
    <>
      {errors?.root ? <FormError>{errors?.root?.message}</FormError> : null}
      {fields.map(field => {
        const attributes = {
          className: getInputClasses(field),
          id: field,
          label: labels[field],
          name: field,
          ...(field === 'email' ? {} : { type: 'password' }),
        };

        return <TextFieldRHF {...attributes} key={field} />;
      })}
    </>
  );
};
