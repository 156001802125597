import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaBalanceDetails } from '@schemas/opsPortal/types/monetaBalanceDetails';
import { getSystemRemainder } from '@utils/getSystemRemainder';
import { IContractDetailsDisplayData } from '../../utils/types';

interface IMapContractDetailsDataProps {
  data: IContractDetails;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  monetaBalanceDetails?: IOpsPortalMonetaBalanceDetails;
}

export const mapContractDetailsData = ({
  data,
  getLocaleCurrencyValue,
  monetaBalanceDetails,
}: IMapContractDetailsDataProps): IContractDetailsDisplayData => {
  const { amount, amountRefunded, totalInterest, writeOffAmount } =
    objectKeysToCamelCase(data);

  const systemRemainder = getSystemRemainder(
    data.total_balance,
    monetaBalanceDetails?.total_contract_movement,
  );
  const amountReimbursedToCustomer =
    monetaBalanceDetails?.total_customer_refund;
  const received = monetaBalanceDetails?.total_paid;
  const clawback = monetaBalanceDetails?.total_clawback;

  const totalInterestValue =
    Number(totalInterest.amount) > 0
      ? getLocaleCurrencyValue({
          currency: totalInterest.currency,
          value: totalInterest.amount,
        })
      : null;

  const totalAmountValue = totalInterestValue
    ? getLocaleCurrencyValue({
        currency: amount.currency,
        value: (
          Number(amount.amount) + Number(totalInterest.amount)
        ).toString(),
      })
    : null;

  return addPlaceholderForEmptyValues<IContractDetailsDisplayData>({
    originalAmount: getLocaleCurrencyValue({
      currency: amount.currency,
      value: amount.amount,
    }),
    ...(totalInterestValue ? { totalInterest: totalInterestValue } : {}),
    ...(totalAmountValue ? { totalAmount: totalAmountValue } : {}),
    currentAmount:
      systemRemainder &&
      getLocaleCurrencyValue({
        currency: systemRemainder.currency,
        value: systemRemainder.amount,
      }),
    amountRefunded:
      amountRefunded?.amount &&
      getLocaleCurrencyValue({
        currency: amountRefunded.currency,
        value: amountRefunded.amount,
      }),
    amountReimbursedToCustomer:
      amountReimbursedToCustomer?.amount &&
      getLocaleCurrencyValue({
        currency: amountReimbursedToCustomer.currency,
        value: amountReimbursedToCustomer.amount,
      }),
    amountPaid:
      received?.amount &&
      getLocaleCurrencyValue({
        currency: received.currency,
        value: received.amount,
      }),
    amountClawback:
      clawback?.amount &&
      getLocaleCurrencyValue({
        currency: clawback?.currency,
        value: clawback?.amount,
      }),
    writeOffAmount:
      writeOffAmount?.amount &&
      getLocaleCurrencyValue({
        currency: writeOffAmount.currency,
        value: writeOffAmount.amount,
      }),
  });
};
