import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import {
  ILanguageCode,
  IOpsPortalApplicationDetails,
} from '@schemas/opsPortal/types/applicationDetails';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { getCheckoutUrlFromRecentCommunicationsEvent } from '../utils';
import { useGetConsumerCommunicationsByTriggerQuery } from './queries/useGetConsumerCommunicationsByTriggerQuery';

interface IUseSendCheckoutEmail {
  applicationData?: IOpsPortalApplicationDetails;
  lang?: ILanguageCode;
}

export const useSendCheckoutEmail = ({
  applicationData,
  lang,
}: IUseSendCheckoutEmail) => {
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast();

  const {
    consumer_uuid: consumerUuid,
    contract_uuid: contractUuid,
    external_contract_uuid: externalContractUUID,
    merchant_uuid: merchantUuid,
  } = applicationData ?? {};

  const { consumerCommunicationsByTriggerData } =
    useGetConsumerCommunicationsByTriggerQuery({
      consumerUuid,
      communicationTrigger: 'CHECKOUT_LINK',
      contractUuid,
    });

  const checkoutLink = getCheckoutUrlFromRecentCommunicationsEvent(
    consumerCommunicationsByTriggerData,
  );

  const { mutate: sendCheckoutSms } = useMutation<
    ArrayBuffer,
    AxiosError<IError>,
    void
  >(
    () =>
      postDataToEndpoint({
        endpoint: `${Endpoints.SendCheckoutEmail}/${externalContractUUID}`,
        requestData: { lang, merchantUuid },
      }),
    {
      onSuccess: () => {
        message.success(translate('LABEL.SEND_CHECKOUT_SMS_SUCCESS'));
      },
      onError: error => {
        handleAxiosError(error);
      },
    },
  );

  const sendCheckoutEmailButton = checkoutLink
    ? [
        {
          clickHandler: sendCheckoutSms,
          buttonKey: 'sendSms',
          text: translate('OPS_PORTAL.BUTTON.SEND_CHECKOUT_EMAIL'),
          variant: ButtonVariant.Primary,
        },
      ]
    : [];

  return { sendCheckoutEmailButton };
};
