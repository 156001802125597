import { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { actions, loginUserPassword } from '@auth/_redux';
import {
  ButtonWrapper,
  AuthButtonWrapper,
  LinkWrapper,
  Spinner,
  SpinnerButton,
} from '@auth/pages/Auth.styles';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortal } from '@hooks/usePortal/usePortal';
import { AuthPathnames } from '@utils/constants';
import { ButtonVariant, ValidationErrorKeys } from '@utils/enums';
import { LoginUserPassPageHeader } from '../Headers/LoginUserPassPageHeader';
import { OriginatorRouterLink } from '../OriginatorRouterLink';
import { updateStepFromUserMfaSettings } from '../utils/updateStepFromUserMfaSettings';
import { AuthFormFields } from './AuthFormFields';
import { ForgotPasswordLink } from './ForgotPasswordLink';
import { AuthField, Values, IAuthFormLoginUserPassword } from './types';
import { useGetAuthFormDetails } from './useGetAuthFormDetails';

export const AuthFormLoginUserPassword = ({
  handleStepChange,
  isConsumerPortal,
}: IAuthFormLoginUserPassword) => {
  const dispatch = useDispatch();
  const { isOpsPortal } = usePortal();
  const { translate } = useI18n();
  const { isMfaCapabilityEnabled } = useFeatureFlags();
  const fields: Array<AuthField> = ['email', 'password'];
  const initialValues = { email: '', password: '' };
  const { labels, schemaShape } = useGetAuthFormDetails({
    fields,
    isLogin: true,
  });
  const methods = useForm({
    defaultValues: initialValues,
    mode: 'onBlur',
    resolver: yupResolver(Yup.object().shape(schemaShape)),
  });

  const onSubmit = useCallback(
    async (values: Values) => {
      try {
        const {
          data: { access },
        } = await loginUserPassword(
          values?.email ?? '',
          values?.password ?? '',
        );

        if (!isMfaCapabilityEnabled || !isOpsPortal) {
          dispatch(actions.login(access));
        } else {
          dispatch(actions.setAuthTokenAndIsAwaitingMfaAuth(access, true));
          updateStepFromUserMfaSettings({
            accessToken: access,
            dispatch,
            handleStepChange,
          });
        }
      } catch {
        methods.setError('root', {
          message: translate(ValidationErrorKeys.InvalidLogin),
        });
      }
    },
    [
      dispatch,
      handleStepChange,
      isMfaCapabilityEnabled,
      isOpsPortal,
      translate,
      methods,
    ],
  );

  return (
    <>
      <LoginUserPassPageHeader translate={translate} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AuthFormFields
            errors={methods.formState.errors}
            fields={fields}
            hasSubmitted={methods.formState.isSubmitted}
            labels={labels}
            touched={
              methods.formState.touchedFields as Partial<Record<string, string>>
            }
          />
          <AuthButtonWrapper>
            <LinkWrapper>
              <ForgotPasswordLink translate={translate} />
              {isConsumerPortal ? (
                <OriginatorRouterLink
                  className="text-dark-50 text-hover-primary"
                  id="kt_login_forgot"
                  to={AuthPathnames.REQUEST_REGISTRATION}
                >
                  {translate('AUTH.RECEIVE_ACCOUNT_ACCESS_LINK')}
                </OriginatorRouterLink>
              ) : null}
            </LinkWrapper>
            <ButtonWrapper>
              <SpinnerButton
                disabled={methods.formState.isSubmitting}
                id="kt_login_signin_submit"
                type="submit"
                variant={ButtonVariant.Primary}
              >
                <span>{translate('AUTH.SIGN_IN')}</span>
                {methods.formState.isSubmitting ? <Spinner /> : null}
              </SpinnerButton>
            </ButtonWrapper>
          </AuthButtonWrapper>
        </form>
      </FormProvider>
    </>
  );
};
