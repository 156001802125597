import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getStringifiedQueryParams } from '@components/TableV2/helpers/getStringifiedQueryParams';
import { removeEmptyKeysAndReplaceLimitField } from '@components/TableV2/helpers/utils';
import { ITableConfig } from '@components/TableV2/types';
import { CursorPaginationModel } from '@hooks/useCursorPagination/types';
import { useCursorPagination } from '@hooks/useCursorPagination/useCursorPagination';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { useTableSorting } from '@hooks/useTableSorting/useTableSorting';
import { IOpsPortalAgencyAssignedContracts } from '@schemas/opsPortal/types/agencyAssignedContracts';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint/fetchDataFromEndpoint';
import { IAgencyAssignedContractsFilterValues } from '../../types';
import { useGetAgencyAssignedContractsColumns } from './useGetAgencyAssignedContractsColumns';

interface IUseGetAggregatedAgencyAssignmentsTableConfig {
  contractsScreenFilters: IAgencyAssignedContractsFilterValues;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const useGetAgencyAssignedContractsTableConfig = ({
  contractsScreenFilters,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IUseGetAggregatedAgencyAssignmentsTableConfig): ITableConfig<
  IAgencyAssignedContractsFilterValues,
  CursorPaginationModel,
  IOpsPortalAgencyAssignedContracts
> => {
  const sortingSettings = useTableSorting();
  const paginationSettings = useCursorPagination();

  const queryParams = useMemo(
    () =>
      removeEmptyKeysAndReplaceLimitField({
        ...contractsScreenFilters,
        ...paginationSettings.paginationParams,
        ...sortingSettings.sortSearchParam,
      }),
    [
      contractsScreenFilters,
      paginationSettings.paginationParams,
      sortingSettings.sortSearchParam,
    ],
  );

  const queryKey = useMemo(
    () => [
      QueryKeys.AGENCY_ASSIGNED_CONTRACTS,
      getStringifiedQueryParams(queryParams),
    ],
    [queryParams],
  );

  const {
    data: agencyAssignedContractsData,
    error,
    isLoading,
  } = useQuery<IOpsPortalAgencyAssignedContracts, AxiosError>(
    queryKey,
    () =>
      fetchDataFromEndpoint(Endpoints.FetchAgencyAssignments, {
        query: {
          ...(queryParams as object),
        },
      }),
    {
      onSuccess: responseData => {
        paginationSettings.setNextCursor(responseData.next_cursor ?? '');
        paginationSettings.setPrevCursor(responseData.previous_cursor ?? '');
      },
    },
  );

  const columns = useGetAgencyAssignedContractsColumns({
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  return {
    columns,
    data:
      agencyAssignedContractsData ?? ({} as IOpsPortalAgencyAssignedContracts),
    error,
    isLoading,
    paginationSettings,
    sortingSettings,
  };
};
