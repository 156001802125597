import { useMemo, useRef } from 'react';
import { Formik, Form } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { TextField } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IAddressComponentArray } from '@schemas/consumerPortal/types/addressData';
import { AccountSettingsFormNames } from '@utils/constants';
import { IAddressValues, ILegacyAddress } from '../types';
import { FormObserver } from './FormObserver';
import { getCustomAddressComponents } from './getCustomAddressComponents';
import { getFormattedAddress } from './getFormattedAddress';
import { getManualAddressFormData } from './getManualAddressFormData';
import { getValidationSchema } from './getValidationSchema';

export interface IManualAddressFormProps {
  addressComponents?: IAddressComponentArray;
  formID: string;
  handleChange: (newAddress: ILegacyAddress) => void;
  handleSubmit: () => void;
}

export const ManualAddressForm = ({
  addressComponents = [],
  formID,
  handleChange,
  handleSubmit,
}: IManualAddressFormProps) => {
  const { translate } = useI18n();

  const initialFormValues = useMemo(
    () => getManualAddressFormData(addressComponents),
    [addressComponents],
  );

  const onChange = useRef((formValues: IAddressValues) => {
    handleChange({
      formatted_address: getFormattedAddress(formValues),
      address_components: getCustomAddressComponents({
        addressComponents,
        formValues,
      }),
    });
  }).current;

  const form = AccountSettingsFormNames.MANUAL_ADDRESS;

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema(form)({ translate })}
    >
      <Form id={formID}>
        <TextField
          id="address_street_number"
          label={translate('LABEL.ADDRESS_STREET_NUMBER')}
          name="address_street_number"
        />
        <TextField
          id="address_first_line"
          label={translate('LABEL.ADDRESS_FIRST_LINE')}
          name="address_first_line"
        />
        <TextField
          id="address_second_line"
          label={translate('LABEL.ADDRESS_SECOND_LINE')}
          name="address_second_line"
        />
        <Row>
          <Col sm="6" xs="12">
            <TextField
              containerClassName="mb-2"
              id="address_post_code"
              label={translate('LABEL.ADDRESS_POST_CODE')}
              name="address_post_code"
            />
          </Col>
          <Col sm="6" xs="12">
            <TextField
              containerClassName="mb-2"
              id="address_town"
              label={translate('LABEL.ADDRESS_TOWN')}
              name="address_town"
            />
          </Col>
        </Row>
        <FormObserver onChange={onChange} />
      </Form>
    </Formik>
  );
};
