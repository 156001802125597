import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { actions, resetPassword } from '@auth/_redux';
import { useI18n } from '@hooks/useI18n/useI18n';
import { AuthPageHeader, ContentInnerWrapper } from './Auth.styles';
import { AuthForm } from './components/AuthForm';
import { AuthField, Values } from './components/AuthForm/types';
import { useGetAuthFormDetails } from './components/AuthForm/useGetAuthFormDetails';
import { getRequestErrorKey } from './getRequestErrorKey';

export const ResetPassword = () => {
  const { translate } = useI18n();
  const dispatch = useDispatch();
  const { token } = useParams();

  const fields: AuthField[] = ['password', 'passwordconfirm'];
  const { labels, schemaShape } = useGetAuthFormDetails({
    fields,
    isLogin: false,
  });

  const methods = useForm({
    defaultValues: { password: '', passwordconfirm: '' } as Values,
    mode: 'onBlur',
    resolver: yupResolver(Yup.object().shape(schemaShape)),
  });

  const onSubmit = useCallback(
    async (values: Values) => {
      if (token) {
        try {
          const {
            data: { access },
          } = await resetPassword(token, values?.password ?? '');
          dispatch(actions.passwordReset(access));
        } catch (error) {
          const errorKey = getRequestErrorKey(error);
          methods.setError('root', {
            message: translate(errorKey),
          });
        }
      }
    },
    [dispatch, methods, token, translate],
  );

  return (
    <ContentInnerWrapper>
      <AuthPageHeader>
        <h2>{translate('AUTH.ENTER_YOUR_NEW_PASSWORD')}</h2>
      </AuthPageHeader>

      <AuthForm
        fields={fields}
        handleSubmit={onSubmit}
        labels={labels}
        methods={methods}
      />
    </ContentInnerWrapper>
  );
};
