import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropdownRHF as Dropdown } from '@components/Dropdown';
import { TextFieldRHF } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IAllocationType } from '@schemas/opsPortal/types/allocationDetails';
import { ValidationErrorKeys } from '@utils/enums';
import { AllocationType } from './enums';
import {
  IArrearsManagedByRequest,
  IAllocationDetailsForm,
  IGetAllocationTypeValidation,
  IFields,
} from './types';

const prefix = 'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.';

const allocationTypes: Record<IAllocationType, string> = {
  [AllocationType.FailedMerchantTransaction]: `${prefix}MERCHANT_TRANSACTION`,
  [AllocationType.ManualPayment]: `${prefix}MANUAL_PAYMENT`,
  [AllocationType.ManualRefund]: `${prefix}MANUAL_REFUND`,
  [AllocationType.MerchantPayment]: `${prefix}MERCHANT_PAYMENT`,
  [AllocationType.MerchantSettlement]: `${prefix}MERCHANT_SETTLEMENT`,
  [AllocationType.StripePayout]: `${prefix}STRIPE_PAYOUT`,
  [AllocationType.TransferCompass]: `${prefix}TRANSFER_COMPASS`,
  [AllocationType.TransferCurrent]: `${prefix}TRANSFER_CURRENT`,
  [AllocationType.TransferMagicpass]: `${prefix}TRANSFER_MAGICPASS`,
  [AllocationType.TransferNcp]: `${prefix}TRANSFER_NCP`,
  [AllocationType.Suspense]: `${prefix}SUSPENSE`,
  [AllocationType.Unallocated]: `${prefix}UNALLOCATED`,
};

const showPaymentPlanReference = (allocationType: IAllocationType) =>
  [AllocationType.ManualPayment, AllocationType.ManualRefund].includes(
    allocationType as AllocationType,
  );

export const AllocationDetailsForm = ({
  amount,
  formID,
  handleSubmitForm,
}: IAllocationDetailsForm) => {
  const { translate } = useI18n();

  const defaultValues: IArrearsManagedByRequest = {
    allocation_type: AllocationType.ManualPayment,
    amount: '',
  };

  const getAllocationTypeValidation = ({
    customValidation,
    maxLength,
    validationAllocationTypes: validationAllocationType,
  }: IGetAllocationTypeValidation) =>
    Yup.string().when('allocation_type', {
      is: (allocationType: IAllocationType) =>
        customValidation
          ? customValidation(allocationType)
          : validationAllocationType?.includes(allocationType),
      then: schema =>
        schema.required(translate(ValidationErrorKeys.Required)).max(maxLength),
      otherwise: schema => schema.notRequired(),
    });

  const validationSchema = Yup.object({
    allocation_type: Yup.string().required(
      translate(ValidationErrorKeys.Required),
    ),
    amount: Yup.string().required(translate(ValidationErrorKeys.Required)),
    payment_plan_reference: getAllocationTypeValidation({
      customValidation: (allocationType: IAllocationType) =>
        showPaymentPlanReference(allocationType),
      maxLength: 8,
    }),
    stripe_reference: getAllocationTypeValidation({
      validationAllocationTypes: [AllocationType.StripePayout],
      maxLength: 6,
    }),
    merchant_settlement_reference: getAllocationTypeValidation({
      validationAllocationTypes: [
        AllocationType.MerchantSettlement,
        AllocationType.MerchantPayment,
      ],
      maxLength: 20,
    }),
    merchant_uuid: getAllocationTypeValidation({
      validationAllocationTypes: [AllocationType.MerchantPayment],
      maxLength: 100,
    }),
  });

  const methods = useForm<IArrearsManagedByRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { handleSubmit, setValue, watch } = methods;

  const chosenAllocationType = watch('allocation_type');
  const amountLabel = `${prefix}${chosenAllocationType}`;
  const currencyLabel = amount?.currency ? ` (${amount.currency})` : '';

  const allocationTypeOptions = Object.entries(allocationTypes).map(
    ([key, value]) => ({
      id: key,
      name: translate(value),
    }),
  );

  const fields: IFields[] = useMemo(
    () => [
      {
        key: 'payment_plan_reference',
        labelKey: 'PAYMENT_PLAN_REFERENCE',
        show: showPaymentPlanReference(chosenAllocationType),
      },
      {
        key: 'stripe_reference',
        labelKey: 'STRIPE_REFERENCE',
        show: chosenAllocationType === AllocationType.StripePayout,
      },
      {
        key: 'merchant_settlement_reference',
        labelKey: 'MERCHANT_SETTLEMENT_REFERENCE',
        show: [
          AllocationType.MerchantSettlement,
          AllocationType.MerchantPayment,
        ].includes(chosenAllocationType as AllocationType),
      },
      {
        key: 'merchant_uuid',
        labelKey: 'MERCHANT_UUID',
        show: chosenAllocationType === AllocationType.MerchantPayment,
      },
    ],
    [chosenAllocationType],
  );

  useEffect(() => {
    fields.forEach(({ key, show }) => {
      if (!show) {
        setValue(key, undefined);
      }
    });
  }, [fields, setValue]);

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={handleSubmit(handleSubmitForm)}>
        <Dropdown
          label={translate('OPS_PORTAL.LABEL.ALLOCATION_TYPE')}
          name="allocation_type"
          options={allocationTypeOptions}
        />
        <TextFieldRHF
          id="amount"
          label={`${translate(amountLabel)}${currencyLabel}`}
          name="amount"
          step="0.01"
          type="number"
        />
        {fields.map(({ key, labelKey, show }) =>
          show ? (
            <TextFieldRHF
              id={key}
              key={key}
              label={translate(`OPS_PORTAL.LABEL.${labelKey}`)}
              name={key}
            />
          ) : null,
        )}
      </form>
    </FormProvider>
  );
};
