/* eslint-disable max-len */
import { IOpsPortalConsumerCommunicationsEventsByCommunicationTrigger } from '@schemas/opsPortal/types/consumerCommunicationsEventsByCommunicationTrigger';

const urlRegex = /https?:\/\/hdpy\.ltd\/[^\s]+/g;

export const getCheckoutUrlFromRecentCommunicationsEvent = (
  data?: IOpsPortalConsumerCommunicationsEventsByCommunicationTrigger,
) => {
  if (!data || data.communication_events.length === 0) {
    return null;
  }

  const sortedEvents = [...data.communication_events].sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
  );

  const recentEventContent = sortedEvents[0]?.content_sent;
  const urls = recentEventContent?.match(urlRegex);

  return urls?.[0];
};
