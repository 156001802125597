/* eslint-disable max-len */
import { TableLimitOffsetV2 } from '@components/TableV2/TableLimitOffsetV2';
import { useI18n } from '@hooks/useI18n/useI18n';
import { TableNames } from '@utils/constants';
import { IHandleAgencyScreenChange } from './AgencyAssignments';
import { useGetAggregatedAgencyAssignmentsTableConfig } from './hooks/aggregatedTable/useGetAggregatedAgencyAssignmentsTableConfig';
import { getTotalBucketCountsPerDate } from './utils/getTotalBucketCountsPerDate';
import { getTotalBucketCountsPerDatePerAgency } from './utils/getTotalBucketCountsPerDatePerAgency';
import { removeAllZeroRows } from './utils/removeAllZeroRows';

interface IAggregatedAgencyAssignments {
  handleAgencyScreenChange: ({
    filters,
    isOnAggregateScreen,
  }: IHandleAgencyScreenChange) => void;
}

export const AggregatedAgencyAssignments = ({
  handleAgencyScreenChange,
}: IAggregatedAgencyAssignments) => {
  const { translate } = useI18n();

  const {
    columns,
    data: agencyAssignmentsData,
    error,
    filtersSettings,
    hasDates,
    isLoading,
    paginationSettings,
    sortingSettings,
    toggleSettings,
  } = useGetAggregatedAgencyAssignmentsTableConfig({
    handleAgencyScreenChange,
    translate,
  });

  const aggregatedAssignmentData = getTotalBucketCountsPerDate(
    agencyAssignmentsData,
  );
  const aggregatedAssignmentDataByAgency = getTotalBucketCountsPerDatePerAgency(
    agencyAssignmentsData,
  );
  const data = removeAllZeroRows(
    toggleSettings?.value
      ? aggregatedAssignmentDataByAgency
      : aggregatedAssignmentData,
  );

  return (
    <TableLimitOffsetV2
      columns={columns}
      count={data.length}
      data={hasDates ? data : []}
      error={error}
      filterSettings={filtersSettings}
      isLoading={isLoading}
      name={TableNames.AGENCY_ASSIGMENTS}
      paginationSettings={paginationSettings}
      sortingSettings={sortingSettings}
      title={translate('OPS_PORTAL.TITLE.AGENCY_ASSIGNMENTS')}
      toggleSettings={toggleSettings}
    />
  );
};
