/* eslint-disable max-len */
import { getDatesToFromToday } from '@components/TableV2/helpers/getDatesToFromToday';
import { PageRoutes } from '@utils/enums';
import { INavItems } from '../NavItems';

const {
  from: defaultAssignmentStartDateFrom,
  to: defaultAssignmentStartDateTo,
} = getDatesToFromToday(7);

export const ContractNavItems: INavItems = Object.freeze({
  AGENCY_ASSIGNMENTS: {
    iconPath: '/media/svg/icons/Communication/Add-user.svg',
    link: `${PageRoutes.AgencyAssignments}?assignmentStartDateFrom=${defaultAssignmentStartDateFrom}&assignmentStartDateTo=${defaultAssignmentStartDateTo}`,
    scopes: ['ops_portal.can_view_agency_assignments'],
    translationKey: 'OPS_PORTAL.TITLE.AGENCY_ASSIGNMENTS',
  },
  ALL_CONTRACTS: {
    iconPath: '/media/svg/icons/Files/Folder-check.svg',
    link: PageRoutes.AllContracts,
    scopes: ['ops_portal.view_contract'],
    translationKey: 'OPS_PORTAL.TITLE.ALL_CONTRACTS',
  },
  AWAITING_DISPATCH: {
    iconPath: '/media/svg/icons/Code/Time-schedule.svg',
    link: PageRoutes.AwaitingDispatch,
    scopes: ['ops_portal.view_awaiting_dispatch_contracts'],
    translationKey: 'STATUS.AWAITING_DISPATCH',
  },
  COLLECTIONS: {
    iconPath: '/media/svg/icons/Code/Warning-2.svg',
    link: `${PageRoutes.Collections}?performanceStatus=overdue`,
    scopes: ['ops_portal.view_collections_contracts'],
    translationKey: 'OPS_PORTAL.TITLE.COLLECTIONS',
  },
  CONTRACTS: {
    iconPath: '/media/svg/icons/Files/Folder-check.svg',
    link: PageRoutes.Contracts,
    scopes: ['ops_portal.view_contract'],
    translationKey: 'OPS_PORTAL.TITLE.CONTRACTS',
  },
  OVERPAID_CONTRACTS: {
    iconPath: '/media/svg/icons/Files/Folder-plus.svg',
    link: PageRoutes.OverpaidContracts,
    scopes: ['ops_portal.view_overpaid_contracts'],
    translationKey: 'OPS_PORTAL.TITLE.OVERPAID_CONTRACTS',
  },
  CLAWBACKS: {
    iconPath: '/media/svg/icons/Files/Deleted-folder.svg',
    link: PageRoutes.Clawbacks,
    scopes: ['ops_portal.can_view_merchant_clawbacks'],
    translationKey: 'OPS_PORTAL.TITLE.CLAWBACKS',
  },
  PAUSED_CONTRACTS: {
    iconPath: '/media/svg/icons/Media/Pause.svg',
    link: PageRoutes.PausedContracts,
    scopes: ['ops_portal.view_paused_contracts'],
    translationKey: 'OPS_PORTAL.TITLE.PAUSED_CONTRACTS',
  },
  TERMINABLE_CONTRACTS: {
    iconPath: '/media/svg/icons/Code/Stop.svg',
    link: PageRoutes.TerminableContracts,
    scopes: ['ops_portal.can_view_termination_eligible_contracts'],
    translationKey: 'OPS_PORTAL.TITLE.ELIGIBLE_FOR_TERMINATION',
  },
  UTILITIES: {
    iconPath: '/media/svg/icons/General/Thunder.svg',
    link: PageRoutes.Utilities,
    scopes: ['ops_portal.can_access_utilities'],
    translationKey: 'OPS_PORTAL.TITLE.UTILITIES',
  },
});
