import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation } from '@tanstack/react-query';
import { postFormDataToEndpoint } from '@utils/postDataToEndpoint';

export interface IFileUploadRequest {
  endpoint: string;
  onError: () => void;
  onSuccess: () => void;
}

export const useFileUploadQuery = ({
  endpoint,
  onError,
  onSuccess,
}: IFileUploadRequest) => {
  const { isLoading, mutate } = useMutation<void, AxiosError<IError>, File[]>(
    files => {
      const formData = new FormData();
      formData.append('file', files[0], files[0].name);

      return postFormDataToEndpoint({
        endpoint,
        requestData: formData,
      });
    },
    {
      onSuccess,
      onError,
    },
  );

  return {
    isLoading,
    mutate,
  };
};
