import { NoWrap } from '@components/Common.styles';
import {
  TableColumns,
  createTableColumnsV2,
} from '@components/TableV2/helpers/createTableColumnsV2';
import { useI18n } from '@hooks/useI18n';
import { ILateFee } from '@schemas/opsPortal/types/lateFees';
import { missingValuePlaceholder } from '@utils/constants';
import { ActionStatusPill } from './components/ActionStatusPill';

export const useGetLateFeesTableColumns = () => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const columns: TableColumns<ILateFee> = [
    {
      field: 'created',
      label: translate('OPS_PORTAL.LABEL.CREATED_DATE'),
      mapCustomValue: ({ row }) => getLocaleDate({ date: row?.created }),
    },
    {
      field: 'fee_amount',
      label: translate('LABEL.AMOUNT'),
      mapCustomJSX: ({
        row: {
          fee_amount: { amount, currency },
        },
      }) => (
        <NoWrap>{getLocaleCurrencyValue({ value: amount, currency })}</NoWrap>
      ),
    },
    {
      field: 'pushed_to_core',
      label: translate('OPS_PORTAL.LABEL.DATE_OF_APPLICATION'),
      mapCustomValue: ({ row }) =>
        row?.pushed_to_core
          ? getLocaleDate({ date: row?.pushed_to_core })
          : missingValuePlaceholder,
    },
    {
      field: 'not_charged_reason',
      label: translate('OPS_PORTAL.LABEL.REASON_FEE_NOT_APPLIED'),
      mapCustomValue: ({ row }) =>
        row?.not_charged_reason
          ? translate(
              `OPS_PORTAL.STATUS.REASON_LATE_FEES_NOT_CHARGED.${row?.not_charged_reason}`,
            )
          : missingValuePlaceholder,
    },
    {
      field: 'action_status',
      label: translate('OPS_PORTAL.LABEL.ACTION_STATUS'),
      mapCustomJSX: ({ row }) => {
        return <ActionStatusPill status={row?.action_status} />;
      },
    },
  ];

  return createTableColumnsV2({ columns });
};
