import { ChangeEvent, useCallback, useState } from 'react';
import { useI18n } from '@hooks/useI18n/useI18n';
import { Endpoints } from '@utils/enums';
import { DocumentTypes } from './enums';
import {
  IDependencyValues,
  IDocumentType,
  IUseDocumentTypeReturn,
} from './types';

const getDocumentTypes = ({
  hasDownloadedAgreement,
  hasUploadedSignedAgreement,
  isManualSigningRequired,
}: IDependencyValues): IDocumentType[] => [
  {
    id: DocumentTypes.UploadCustomerAgreement,
    name: 'LABEL.CUSTOMER_AGREEMENT',
    description: 'PAGE.ORDERS.UPLOAD_CUSTOMER_AGREEMENT_DESCRIPTION',
    endpoint: Endpoints.CustomerAgreement,
    dependencies: [
      Boolean(isManualSigningRequired),
      Boolean(hasDownloadedAgreement),
      !hasUploadedSignedAgreement,
    ],
  },
];

interface IUseDocumentType {
  dependencyValues: IDependencyValues;
}

export const useDocumentType = ({
  dependencyValues,
}: IUseDocumentType): IUseDocumentTypeReturn => {
  const { translate } = useI18n();

  const documentTypes = getDocumentTypes(dependencyValues);

  const documentTypeOptions = documentTypes
    .filter(docType => docType.dependencies.every(Boolean))
    .map(docType => ({
      id: docType.id,
      name: translate(docType.name.toUpperCase()),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const [documentType, setDocumentType] = useState(documentTypes[0]);

  const handleChangeDocumentType = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const selectedDocumentTypeName = event.target.value;
      const docType = documentTypes.find(
        doc => doc.id === selectedDocumentTypeName,
      );

      if (docType) {
        setDocumentType(docType);
      }
    },
    [documentTypes],
  );

  return { documentTypeOptions, handleChangeDocumentType, documentType };
};
