import { useState, useEffect, useCallback } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation } from '@tanstack/react-query';
import { usePortalError } from '@hooks/usePortalError';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import {
  IEmailTemplate,
  ISupportedLanguage,
  ISelectTemplateProps,
  IHandleSelectTemplateProps,
} from './types';

export const useTemplateState = (uuid: string) => {
  const { handleAxiosError } = usePortalError();
  const [selectedTemplate, setSelectedTemplate] = useState<IEmailTemplate>();
  const [language, setLanguage] = useState<ISupportedLanguage>();
  const [triggerId, setTriggerId] = useState('');

  const {
    data: template,
    isLoading,
    mutate: selectTemplate,
  } = useMutation<IEmailTemplate, AxiosError<IError>, ISelectTemplateProps>(
    requestData =>
      postDataToEndpoint({
        endpoint: Endpoints.SelectEmailTemplate,
        requestData,
      }),
    {
      onError: error =>
        handleAxiosError(error, 'OPS_PORTAL.STATUS.UNABLE_TO_FETCH_TEMPLATE'),
    },
  );

  useEffect(() => setSelectedTemplate(template), [template]);

  const handleSelectTemplate = useCallback(
    (values: IHandleSelectTemplateProps) => {
      setLanguage(values.language);
      setTriggerId(values.template);

      const params: ISelectTemplateProps = {
        contract_external_uuid: uuid,
        language: values.language,
        trigger_id: values.template,
      };

      selectTemplate(params);
    },
    [selectTemplate, uuid],
  );

  return {
    selectedTemplate,
    language,
    triggerId,
    isLoading,
    handleSelectTemplate,
    setLanguage,
    setTriggerId,
    setSelectedTemplate,
  };
};
