import { useCallback, useState } from 'react';
import { styled } from '@mui/material';
import { CenteringWrapper } from '@auth/pages/Auth.styles';
import { Button } from '@components/Button';
import { QrCode } from '@components/QrCode';
import { useI18n } from '@hooks/useI18n/useI18n';
import { ButtonVariant } from '@utils/enums';
import { LoginMFASetupPageHeader } from '../Headers/LoginMFASetupPageHeader';
import { AuthFormLoginMFA } from './AuthFormLoginMFA';
import { SecretKeyField } from './SecretKeyField';
import { IAuthFormLoginSetupMFA } from './types';

const StyledListElement = styled('li')({
  paddingBottom: '2rem',
});

export const AuthFormMFASetup = ({
  accessTokenUserPass,
  handleStepChange,
  isSkippable,
  secret,
  totpUrl,
}: IAuthFormLoginSetupMFA) => {
  const { translate } = useI18n();
  const [showQr, setShowQr] = useState(true);

  const handleSecretFormatChange = useCallback(() => {
    setShowQr(prev => !prev);
  }, []);

  return (
    <>
      <LoginMFASetupPageHeader translate={translate} />
      <ol>
        <StyledListElement>
          {translate('OPS_PORTAL.LABEL.SETUP_MFA_OPEN_APP')}
        </StyledListElement>
        <StyledListElement>
          {translate(
            showQr
              ? 'OPS_PORTAL.LABEL.SETUP_MFA_SCAN_QR'
              : 'OPS_PORTAL.LABEL.SETUP_MFA_ENTER_SECRET_INTO_APP',
          )}
        </StyledListElement>
        <CenteringWrapper>
          {showQr ? (
            <QrCode
              altText={translate('OPS_PORTAL.LABEL.SETUP_MFA_TOTP_QR_CODE')}
              size={220}
              text={totpUrl ?? ''}
            />
          ) : (
            <SecretKeyField secret={secret ?? ''} translate={translate} />
          )}
        </CenteringWrapper>
        <CenteringWrapper>
          <Button
            onClick={handleSecretFormatChange}
            variant={ButtonVariant.Link}
          >
            {translate(
              showQr
                ? 'OPS_PORTAL.BUTTON.SETUP_MFA_ENTER_KEY_MANUALLY'
                : 'OPS_PORTAL.BUTTON.SETUP_MFA_SCAN_QR_CODE',
            )}
          </Button>
        </CenteringWrapper>
        <StyledListElement>
          {translate('OPS_PORTAL.LABEL.SETUP_MFA_ENTER_6_DIGITS')}
        </StyledListElement>
      </ol>
      <AuthFormLoginMFA
        accessTokenUserPass={accessTokenUserPass}
        handleStepChange={handleStepChange}
        hasHeader={false}
        isSkippable={isSkippable}
      />
    </>
  );
};
