import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { Translate } from '@hooks/useI18n';
import { IOrderDetail as IMerchantOrderDetails } from '@schemas/merchantPortal/types/orderDetails';
import { formatCreditTypeLabel } from '@utils/formatCreditTypeLabel';
import { getFullName } from '@utils/getFullName';
import { getStoreInformation } from '@utils/getStoreInformation';
import { IGetOrderSummaryProps, IOrderSummaryProps } from './types';

export interface IGetMerchantOrderSummaryProps extends IGetOrderSummaryProps {
  isPagolightPro: boolean;
  orderDetailsData: IMerchantOrderDetails;
  showCompassOrders: boolean;
  translate: Translate;
}

export const getMerchantOrderSummaryProps = ({
  getLocaleCurrencyValue,
  getLocaleDate,
  isPagolightPro,
  orderDetailsData,
  showCompassOrders,
  translate,
}: IGetMerchantOrderSummaryProps): IOrderSummaryProps => {
  const {
    amount: { amount, currency },
    annualisedInterestRate,
    consumer,
    createdDate: date,
    creditType,
    feeAmount,
    finalPayment,
    mediobancaExternalId,
    merchantContractStatus: status,
    monthlyPaymentAmount,
    numberOfInstallments: numberOfInstalments,
    orderDetails: { orderDescription, orderReference: merchantOrderId },
    paymentPlanReference: paymentPlanId,
    refunded,
  } = objectKeysToCamelCase(orderDetailsData);

  const orderValue = getLocaleCurrencyValue({
    currency,
    value: amount,
  });

  const { firstName, lastName } = consumer;
  const fullName = getFullName({ firstName, lastName });

  const netOrderAmount = getLocaleCurrencyValue({
    currency,
    value: (parseFloat(amount) - parseFloat(feeAmount?.amount ?? '0')).toFixed(
      2,
    ),
  });
  const totalRefund = getLocaleCurrencyValue({
    currency,
    value: refunded.amount,
  });

  if (showCompassOrders) {
    // Do not alphabetise these object properties, as the order
    // of the Object.keys determines display order.
    return addPlaceholderForEmptyValues<IOrderSummaryProps>({
      date: date ? getLocaleDate({ date, includeTime: true }) : null,
      mediobancaExternalId,
      merchantOrderId,
      customerName: fullName,
      numberOfInstalments,
      orderValue,
      ...(isPagolightPro
        ? {
            amountRefunded: getLocaleCurrencyValue({
              currency,
              value: refunded.amount,
            }),
            adjustedOrderValue: getLocaleCurrencyValue({
              currency,
              value: (parseFloat(amount) - parseFloat(refunded.amount)).toFixed(
                2,
              ),
            }),
          }
        : {}),
      status,
    });
  }

  // Do not alphabetise these object properties, as the order
  // of the Object.keys determines display order.
  return addPlaceholderForEmptyValues<IOrderSummaryProps>({
    date: date ? getLocaleDate({ date, includeTime: true }) : null,
    paymentPlanId,
    merchantOrderId,
    customerName: fullName,
    numberOfInstalments,
    orderValue,
    feeAmount:
      feeAmount &&
      getLocaleCurrencyValue({
        currency,
        value: feeAmount.amount,
      }),
    netOrderAmount,
    totalRefund,
    monthlyPaymentAmount:
      monthlyPaymentAmount &&
      getLocaleCurrencyValue({
        currency,
        value: monthlyPaymentAmount.amount,
      }),
    ...(finalPayment
      ? {
          finalPayment: getLocaleCurrencyValue({
            currency,
            value: finalPayment.amount,
          }),
        }
      : {}),
    creditType: formatCreditTypeLabel({ creditType, translate }),
    annualisedInterestRate: `${(annualisedInterestRate ?? 0) * 100}%`,
    orderDescription,
    status,
    ...getStoreInformation(orderDetailsData),
  });
};
