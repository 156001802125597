import {
  useState,
  useRef,
  useCallback,
  FocusEvent,
  KeyboardEvent,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { CodeInput } from '@heidi-pay/heidi-component-library/components/CodeInput';
import { Box } from '@mui/material';
import { TOTPCountdownTimer } from '@auth/pages/components/TOTPCountdownTimer';
import { FormGroup, Label } from '@components/Common.styles';
import { ValidationError } from '@components/ValidationError';

const VERIFICATION_CODE_LENGTH = 6;

interface ITotpCodeInput {
  codeLength?: number;
  containerClassName?: string;
  disabled?: boolean;
  error?: string;
  label: string;
  name: string;
}

export const TotpCodeInputRHF = ({
  codeLength = VERIFICATION_CODE_LENGTH,
  containerClassName,
  disabled = false,
  error,
  label,
  name,
}: ITotpCodeInput) => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  const validationError = errors[name]?.message;
  const errorMessage = validationError || error;

  const [codeArray, setCodeArray] = useState<string[]>(
    Array(codeLength).fill(''),
  );

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  }, []);

  const setInputRef = useCallback(
    (el: HTMLInputElement | null, index: number) => {
      inputRefs.current[index] = el;
    },
    [],
  );

  const handleKeyDown = useCallback(
    (index: number, e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && !codeArray[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    },
    [codeArray],
  );

  const handleChange = useCallback(
    (index: number, inputValue: string) => {
      setCodeArray(prevCode => {
        const newCode = [...prevCode];
        newCode[index] = inputValue.slice(-1);

        if (inputValue && index < codeLength - 1) {
          inputRefs.current[index + 1]?.focus();
        }

        const fullCode = newCode.join('');
        setValue(name, fullCode);

        return newCode;
      });
    },
    [codeLength, name, setValue],
  );

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      const pasteData = e.clipboardData
        .getData('Text')
        .replace(/\D/g, '')
        .slice(0, codeLength);

      if (pasteData.length > 0) {
        const newCodeArray = Array(codeLength).fill('');
        pasteData.split('').forEach((char, i) => {
          newCodeArray[i] = char;
        });

        setCodeArray(newCodeArray);
        setValue(name, newCodeArray.join(''));

        const lastIndex = Math.min(pasteData.length, codeLength) - 1;
        inputRefs.current[lastIndex ?? 0]?.focus();
      }

      e.preventDefault();
    },
    [codeLength, name, setValue],
  );

  return (
    <FormGroup extraClassName={containerClassName}>
      <Label htmlFor={name}>{label}</Label>
      <Box
        alignItems="center"
        display="flex"
        gap={1.2}
        {...register(name)}
        onPaste={handlePaste}
      >
        {codeArray.map((digit, index) => (
          <CodeInput
            digit={digit}
            disabled={disabled}
            error={!!errorMessage}
            index={index}
            key={index}
            label={`codeInput${index}`}
            name={name}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            setInputRef={setInputRef}
          />
        ))}
        <TOTPCountdownTimer />
      </Box>
      {errorMessage ? <ValidationError error={errorMessage} /> : null}
    </FormGroup>
  );
};
