import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';

interface IUseSwissChangeCardToQr {
  contractDataQueryKey?: QueryKey;
  contractUuid?: string;
}

export const useSwissChangeCardToQr = ({
  contractDataQueryKey,
  contractUuid,
}: IUseSwissChangeCardToQr) => {
  const { translate } = useI18n();
  const queryClient = useQueryClient();

  const { message } = useToast();
  const { handleAxiosError } = usePortalError();

  const { mutate: handleChangeCardToQr } = useMutation<
    ArrayBuffer,
    AxiosError<IError>,
    void
  >(
    () =>
      postDataToEndpoint({
        endpoint: `${Endpoints.SwissChangeCardToQr}/${contractUuid}`,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(contractDataQueryKey);
        message.success(
          translate('OPS_PORTAL.LABEL.SWISS_CHANGE_CARD_TO_QR_SUCCESS'),
        );
      },
      onError: error => {
        handleAxiosError(
          error,
          'OPS_PORTAL.LABEL.SWISS_CHANGE_CARD_TO_QR_ERROR',
        );
      },
    },
  );

  return handleChangeCardToQr;
};
