import { FormProvider } from 'react-hook-form';
import {
  ButtonWrapper,
  AuthButtonWrapper,
  Spinner,
  SpinnerButton,
} from '@auth/pages/Auth.styles';
import { Button } from '@components/Button';
import { useI18n } from '@hooks/useI18n/useI18n';
import { AuthPathnames } from '@utils/constants';
import { ButtonVariant } from '@utils/enums';
import { OriginatorRouterLink } from '../OriginatorRouterLink';
import { AuthFormFields } from './AuthFormFields';
import { IAuthFormProps } from './types';

export const AuthForm = ({
  fields,
  handleSubmit,
  labels,
  methods,
}: IAuthFormProps) => {
  const { translate } = useI18n();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <AuthFormFields
          errors={methods.formState.errors}
          fields={fields}
          hasSubmitted={methods.formState.isSubmitted}
          labels={labels}
          touched={
            methods.formState.touchedFields as Partial<Record<string, string>>
          }
        />
        <AuthButtonWrapper justifyContent="flex-end">
          <ButtonWrapper>
            <Button
              as={OriginatorRouterLink}
              to={AuthPathnames.LOGIN}
              variant={ButtonVariant.Secondary}
            >
              {translate('BUTTON.CANCEL')}
            </Button>
            <SpinnerButton
              disabled={methods.formState.isSubmitting}
              id="kt_login_signin_submit"
              type="submit"
              variant={ButtonVariant.Primary}
            >
              <span>{translate('BUTTON.SUBMIT')}</span>
              {methods.formState.isSubmitting ? <Spinner /> : null}
            </SpinnerButton>
          </ButtonWrapper>
        </AuthButtonWrapper>
      </form>
    </FormProvider>
  );
};
