import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { IActionButton, ValueOf } from '@appTypes';
import { Card } from '@components/Card';
import { useGetQrInvoices } from '@hooks/useGetQrInvoices/useGetQrInvoices';
import { useI18n } from '@hooks/useI18n/useI18n';
import {
  useCancelAdjustContractValue,
  useEditAdjustContractValue,
} from '@hooks/useModal/hooks/useAdjustContractValueModal/useAdjustContractValueModal';
import { useConfirmDispatchModal } from '@hooks/useModal/hooks/useConfirmDispatchModal';
import { useDownloadCustomerAgreementModal } from '@hooks/useModal/hooks/useDownloadCustomerAgreementModal';
import { useFileUploadModal } from '@hooks/useModal/hooks/useFileUploadModal';
import { useOrderPaymentModal } from '@hooks/useModal/hooks/useOrderPaymentModal';
import { usePortal } from '@hooks/usePortal';
import { IOrderDetail as IConsumerOrderDetails } from '@schemas/consumerPortal/types/ordersDetails';
import { IOrderDetail as IMerchantOrderDetails } from '@schemas/merchantPortal/types/orderDetails';
import { PaymentTypes } from '@utils/constants';
import { ICheckPermissions, IOrderSummaryProps } from '../utils/types';
import { OrderSummary } from './OrderSummary';

interface IOrderSummaryCard {
  canShowPaymentButton: boolean;
  orderDetailsData: IConsumerOrderDetails | IMerchantOrderDetails;
  orderSummaryProps: IOrderSummaryProps;
  paymentType: ValueOf<typeof PaymentTypes>;
  permissions: ICheckPermissions;
  queryKey: QueryKey;
  uuid: string;
}

export const OrderSummaryCard = ({
  canShowPaymentButton,
  orderDetailsData,
  orderSummaryProps,
  paymentType,
  permissions,
  queryKey,
  uuid,
}: IOrderSummaryCard) => {
  const { translate } = useI18n();

  const queryClient = useQueryClient();
  const { isMerchantPortal } = usePortal();

  const {
    canAdjustContractValue,
    canCancelOrder,
    canConfirmDispatch,
    canMakePayment,
  } = permissions;

  const { amount = '', currency = '' } = orderDetailsData.amount ?? {};

  const {
    external_uuid: externalUuid,
    manual_agreement_signing_overview: manualAgreementSigningOverview,
    payment_method: paymentMethod,
  } = orderDetailsData as IMerchantOrderDetails;

  const {
    has_downloaded_agreement: hasDownloadedAgreement = false,
    has_uploaded_signed_agreement: hasUploadedSignedAgreement = false,
    is_manual_signing_required: isManualSigningRequired = false,
  } = manualAgreementSigningOverview ?? {};

  const { downloadQrInvoiceButton } = useGetQrInvoices({
    externalContractUuid: externalUuid,
    paymentMethod,
  });

  const adjustContractValueModal = useEditAdjustContractValue({
    amount,
    currency,
    dependencies: [canAdjustContractValue],
    queryKey,
    uuid,
  });

  const cancelContractModal = useCancelAdjustContractValue({
    amount,
    currency,
    dependencies: [canCancelOrder],
    queryKey,
    uuid,
  });

  const confirmDispatchModal = useConfirmDispatchModal({
    dependencies: [
      canConfirmDispatch,
      'delay_finalisation' in orderDetailsData &&
        orderDetailsData.delay_finalisation,
    ],
    uuid,
  });

  const paymentModal = useOrderPaymentModal({
    data: orderDetailsData,
    dependencies: [canMakePayment, canShowPaymentButton],
    paymentType,
    queryKey,
    uuid,
  });

  const canUploadManualAgreement = [
    isManualSigningRequired,
    hasDownloadedAgreement,
    !hasUploadedSignedAgreement,
  ].every(Boolean);

  const fileUploadModal = useFileUploadModal({
    acceptedFiles: ['.pdf', '.jpeg', '.jpg'],
    dependencies: [canUploadManualAgreement],
    externalContractUuid: externalUuid,
    dependencyValues: {
      isManualSigningRequired,
      hasDownloadedAgreement,
      hasUploadedSignedAgreement,
    },
    onClose: () => queryClient.invalidateQueries(queryKey),
  });

  const fileDownloadModal = useDownloadCustomerAgreementModal({
    dependencies: [isManualSigningRequired, !hasUploadedSignedAgreement],
    externalApplicationUuid: externalUuid,
    queryKey,
  });

  const manualAgreementSigningButtons = [
    fileDownloadModal?.button,
    fileUploadModal?.button,
  ];

  const buttons = [
    adjustContractValueModal?.button,
    confirmDispatchModal?.button,
    cancelContractModal?.button,
    paymentModal?.button,
    ...(isMerchantPortal ? downloadQrInvoiceButton : []),
    ...manualAgreementSigningButtons,
  ].filter(Boolean) as IActionButton[];

  return (
    <Card buttons={buttons} title={translate('TITLE.ORDER_SUMMARY')}>
      <OrderSummary {...orderSummaryProps} />
    </Card>
  );
};
