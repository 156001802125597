import { QueryKey } from '@tanstack/react-query';
import { IActionButton } from '@appTypes';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import { IPaymentMethod } from '@schemas/opsPortal/types/contractDetails';
import { PaymentMethods } from '@utils/constants';
import { useSwissChangeCardToQr } from '../queries/useSwissChangeCardToQr';

interface IUseSwissChangeCardToQrAction {
  contractDataQueryKey?: QueryKey;
  contractUuid?: string;
  paymentMethod?: IPaymentMethod;
}

export const useSwissChangeCardToQrAction = ({
  contractDataQueryKey,
  contractUuid,
  paymentMethod,
}: IUseSwissChangeCardToQrAction): IActionButton[] => {
  const { translate } = useI18n();
  const { isHeidiPay } = useOriginator();
  const canChangeCardToQr = useHasPermission([
    'ops_portal.can_change_swiss_card_to_qr',
  ]);

  const changeCardToQrTrigger = useSwissChangeCardToQr({
    contractUuid,
    contractDataQueryKey,
  });

  const buttons: IActionButton[] = [
    {
      clickHandler: changeCardToQrTrigger,
      buttonKey: 'changeCardToQrActionButton',
      text: translate('OPS_PORTAL.LABEL.SWISS_CHANGE_CARD_TO_QR'),
      dependencies: [
        Boolean(contractUuid),
        canChangeCardToQr,
        isHeidiPay,
        paymentMethod === PaymentMethods.CARD,
      ],
    },
  ];

  return buttons.filter(({ dependencies }) => dependencies?.every(dep => dep));
};
