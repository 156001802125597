import { getDatesToFromToday } from '@components/TableV2/helpers/getDatesToFromToday';
import { IOpsPortalAggregateAgencyAssignments } from '@schemas/opsPortal/types/aggregateAgencyAssignments';
import {
  AgencyKey,
  AgencyKeys,
  BucketKey,
  BucketKeys,
  IAgencyAssignedContractsFilterValues,
  IAggregateAgencyAssignmentsFilterValues,
} from './types';

export const defaultAggregateAgencyAssignments: IOpsPortalAggregateAgencyAssignments =
  {
    '0005': {},
    CD1: {},
    CD1C: {},
    CD1CC: {},
    CD1F: {},
    CD1I: {},
    CD1Q: {},
    CD1QR: {},
    CD1U: {},
    CD2: {},
    CD3: {},
    CD4: {},
    CD5: {},
    CD6: {},
    CUR: {},
    DEA: {},
    FRA: {},
    FRO: {},
    GTC: {},
    INK: {},
    LL1: {},
    PAP: {},
    TER1: {},
    TME: {},
  };

export const defaultBucketCounts: Record<BucketKey, number> = {
  '0005': 0,
  CD1: 0,
  CD1C: 0,
  CD1CC: 0,
  CD1F: 0,
  CD1I: 0,
  CD1Q: 0,
  CD1QR: 0,
  CD1U: 0,
  CD2: 0,
  CD3: 0,
  CD4: 0,
  CD5: 0,
  CD6: 0,
  CUR: 0,
  DEA: 0,
  FRA: 0,
  FRO: 0,
  GTC: 0,
  INK: 0,
  LL1: 0,
  PAP: 0,
  TER1: 0,
  TME: 0,
};

export const defaultAgenciesCount: Record<AgencyKey, number> = {
  Covisian: 0,
  CreditReform: 0,
  Cribis: 0,
  HeidiPay: 0,
  Inkasso: 0,
  'Sonia Laurenco': 0,
  'Studio Lanza Gia': 0,
};

export const allBucketNames: BucketKeys = Object.keys(
  defaultAggregateAgencyAssignments,
) as BucketKeys;
export const allAgencyNames: AgencyKeys = Object.keys(
  defaultAgenciesCount,
) as AgencyKeys;

const { from, to } = getDatesToFromToday(7);

export const intialAgencyContractsFilterValues: IAgencyAssignedContractsFilterValues =
  {
    assignmentStartDateFrom: from,
    assignmentStartDateTo: to,
    agencyName: '',
    bucketName: '',
  };

export const initialAggregateAssignmentsFilterValues: IAggregateAgencyAssignmentsFilterValues =
  {
    assignmentStartDateFrom: from,
    assignmentStartDateTo: to,
  };
