import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { requestPassword } from '@auth/_redux';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import { HeyLightOriginatorRouteMapping } from '@utils/constants';
import { AuthPageHeader, ContentInnerWrapper } from './Auth.styles';
import { AuthForm } from './components/AuthForm';
import { AuthField, Values } from './components/AuthForm/types';
import { useGetAuthFormDetails } from './components/AuthForm/useGetAuthFormDetails';

export const ForgotPassword = () => {
  const { selectedLanguage, translate } = useI18n();
  const { originator } = useOriginator();
  const navigate = useNavigate();

  const routePrefix = HeyLightOriginatorRouteMapping[originator];

  const fields: AuthField[] = ['email'];
  const { labels, schemaShape } = useGetAuthFormDetails({
    fields,
    isLogin: false,
  });

  const methods = useForm({
    defaultValues: { email: '' } as Values,
    mode: 'onBlur',
    resolver: yupResolver(Yup.object().shape(schemaShape)),
  });

  const onSubmit = useCallback(
    async (values: Values) => {
      try {
        await requestPassword(
          values?.email ?? '',
          selectedLanguage,
          originator,
        );
        navigate(`${routePrefix}/auth/success`, {
          state: {
            heading: translate('AUTH.PASSWORD_RESET_REQUEST_SUBMITTED'),
            text: translate(
              'AUTH.PLEASE_CHECK_YOUR_EMAIL_FOR_FURTHER_INSTRUCTIONS',
            ),
          },
          replace: true,
        });
      } catch (error) {
        methods.setError('root', {
          message: translate('ERROR.UNEXPECTED_ERROR'),
        });
      }
    },
    [methods, navigate, originator, routePrefix, selectedLanguage, translate],
  );

  return (
    <ContentInnerWrapper>
      <AuthPageHeader>
        <h2>{translate('AUTH.FORGOT_PASSWORD')}</h2>
        <p>{translate('AUTH.ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD')}</p>
        <p>{translate('AUTH.USE_PURCHASE_EMAIL_ADDRESS')}</p>
      </AuthPageHeader>

      <AuthForm
        fields={['email']}
        handleSubmit={onSubmit}
        labels={labels}
        methods={methods}
      />
    </ContentInnerWrapper>
  );
};
