import { useCallback } from 'react';
import { useHasPermission } from '@hooks/useHasPermission';
import { useOriginator } from '@hooks/useOriginator';
import {
  IAddress,
  IConsumerPortalAddressData,
} from '@schemas/consumerPortal/types/addressData';
import { LegacyAddressForm } from './LegacyAddress/LegacyAddressForm';
import { SwissPostalAddressForm } from './SwissPostalAddress/SwissPostalAddressForm';
import { AddressRequest, AddressSubmit } from './types';

export interface IEditAddressFormProps {
  data: IConsumerPortalAddressData | undefined;
  formID: string;
  handleSubmit: AddressRequest;
}

export const EditAddressForm = ({
  data,
  formID,
  handleSubmit,
}: IEditAddressFormProps) => {
  const { isHeidiPay } = useOriginator();
  const canChangeSwissPostalAddress = useHasPermission([
    'consumer_portal.can_change_swiss_postal_address',
    'ops_portal.can_change_address',
  ]);

  const initialAddress = data?.data ?? ({} as IAddress);

  const handleSubmitAddress: AddressSubmit = useCallback(
    ({ address, addressFormat }) => {
      handleSubmit({
        data: { address: [address] },
        addressFormat,
      });
    },
    [handleSubmit],
  );

  return (
    <>
      {isHeidiPay && canChangeSwissPostalAddress ? (
        <SwissPostalAddressForm
          formID={formID}
          initialAddress={initialAddress}
          onSubmit={handleSubmitAddress}
        />
      ) : (
        <LegacyAddressForm
          formID={formID}
          initialAddress={initialAddress}
          onSubmit={handleSubmitAddress}
        />
      )}
    </>
  );
};
