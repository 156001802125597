import { useCallback, useState } from 'react';
import { Button } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import {
  IAddress,
  IAddressComponentArray,
} from '@schemas/consumerPortal/types/addressData';
import { ButtonVariant } from '@utils/enums';
import { AddressChangeSummary } from '../AddressChangeSummary';
import { RawAddressFormatType } from '../enums';
import { AddressSubmit, ILegacyAddress } from '../types';
import { ManualAddressForm } from './ManualAddressForm';
import { SearchAddressForm } from './SearchAddressForm';

export interface ILegacyAddressFormProps {
  formID: string;
  initialAddress: IAddress;
  onSubmit: AddressSubmit;
}

export const LegacyAddressForm = ({
  formID,
  initialAddress,
  onSubmit,
}: ILegacyAddressFormProps) => {
  const { translate } = useI18n();
  const [isAddressFormVisible, setIsAddressFormVisible] = useState(false);
  const [address, setAddress] = useState<ILegacyAddress>({
    formatted_address: initialAddress?.formatted_address ?? '',
    address_components: initialAddress?.address_components ?? [],
  });

  const isAddressComponentsAnArray = Array.isArray(address?.address_components);
  const isButtonVisible =
    isAddressComponentsAnArray &&
    (address?.address_components as IAddressComponentArray)?.length;
  const buttonText = translate(
    isAddressFormVisible ? 'BUTTON.SEARCH_ADDRESS' : 'BUTTON.MANUAL_ADDRESS',
  );

  const handleChange = useCallback((newAddress: ILegacyAddress) => {
    setAddress(currentAddress => ({ ...currentAddress, ...newAddress }));
  }, []);

  const toggleEditAddressForm = useCallback(
    () => setIsAddressFormVisible(!isAddressFormVisible),
    [isAddressFormVisible],
  );

  const handleSubmit = useCallback(
    (rawAddressFormat: RawAddressFormatType) => () => {
      onSubmit({
        address: {
          formatted_address: address.formatted_address,
          address_components: address.address_components,
        },
        addressFormat: rawAddressFormat,
      });
    },
    [address, onSubmit],
  );

  return (
    <>
      {isAddressFormVisible ? (
        <ManualAddressForm
          addressComponents={
            address?.address_components as IAddressComponentArray
          }
          formID={formID}
          handleChange={handleChange}
          handleSubmit={handleSubmit(RawAddressFormatType.Manual)}
        />
      ) : (
        <SearchAddressForm
          address={address}
          formID={formID}
          handleChange={handleChange}
          handleSubmit={handleSubmit(RawAddressFormatType.Google)}
          initialAddress={initialAddress}
        />
      )}
      {isButtonVisible ? (
        <Button
          className="px-0 pb-0"
          onClick={toggleEditAddressForm}
          type="button"
          variant={ButtonVariant.Link}
        >
          {buttonText}
        </Button>
      ) : null}
      <AddressChangeSummary
        initialFormattedAddress={initialAddress?.formatted_address}
        newFormattedAddress={address?.formatted_address}
      />
    </>
  );
};
