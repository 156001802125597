import { useI18n } from '@hooks/useI18n';
import { IUuid } from '@schemas/opsPortal/types/contractDetails';
import { TabEventKeys, TableNames } from '@utils/constants';
import { LateFeesTable } from './LateFeesTable/LateFeesTable';

interface IUseLateFeesTab {
  contractUuid?: IUuid;
}
export const useLateFeesTab = ({ contractUuid }: IUseLateFeesTab) => {
  const { translate } = useI18n();
  return {
    component: <LateFeesTable contractUuid={contractUuid} />,
    eventKey: TabEventKeys.LATE_FEES,
    title: translate('OPS_PORTAL.LABEL.LATE_FEES'),
    tableName: TableNames.LATE_FEES,
  };
};
