import { Translate } from '@hooks/useI18n';
import { AuthPathnames } from '@utils/constants';
import { OriginatorRouterLink } from '../OriginatorRouterLink';

interface IForgotPassword {
  translate: Translate;
}

export const ForgotPasswordLink = ({ translate }: IForgotPassword) => (
  <OriginatorRouterLink
    className="text-dark-50 text-hover-primary"
    id="kt_login_forgot"
    to={AuthPathnames.FORGOT_PASSWORD}
  >
    {translate('AUTH.FORGOT_PASSWORD')}
  </OriginatorRouterLink>
);
