import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { IApplicationsTableData } from '@appTypes';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IApplications } from '@schemas/opsPortal/types/applications';
import { formatCreditTypeLabel } from '@utils/formatCreditTypeLabel';
import { getFullName } from '@utils/getFullName';
import { getOriginatorDisplayNameHeyLight } from '@utils/getOriginatorDisplayNameHeyLight';
import { getTranslatedData } from '@utils/getTranslatedData';

interface IMapApplicationsTableDataProps {
  data?: IApplications;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  isMerchantPortal: boolean;
  translate: Translate;
}

export const mapApplicationsTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  isMerchantPortal,
  translate,
}: IMapApplicationsTableDataProps): IApplicationsTableData[] =>
  data?.map(dataRow => {
    const {
      amount: amountObj,
      applicationReference,
      created: createdDate,
      creditType,
      crifScore,
      externalContractId: praticaId,
      externalContractUuid: contractId,
      firstName,
      languageCode,
      lastName,
      merchantName: merchant,
      merchantOrderReference,
      originationChannel,
      originatorName,
      paymentPlanReference: paymentPlanId,
      productExternalId,
      productSku,
      status: activityStatus,
      storeNumber,
      uuid: applicationUuid,
    } = objectKeysToCamelCase(dataRow);

    const amount = amountObj?.amount;
    const currency = amountObj?.currency;

    return addPlaceholderForEmptyValues<IApplicationsTableData>({
      activityStatus: isMerchantPortal
        ? translate(`STATUS.TRACKING_${activityStatus.toUpperCase()}`)
        : getTranslatedData({ term: activityStatus, translate }),
      amount: getLocaleCurrencyValue({ currency, value: amount }),
      applicationReference,
      applicationUuid,
      contractId,
      creditType: formatCreditTypeLabel({ creditType, translate }),
      createdDate:
        createdDate && getLocaleDate({ date: createdDate, includeTime: true }),
      crifScore: crifScore?.toString(),
      customerName: getFullName({ firstName, lastName }),
      language:
        languageCode &&
        translate(`OPS_PORTAL.LABEL.${languageCode.toUpperCase()}`),
      merchant,
      merchantOrderReference,
      originationChannel: getTranslatedData({
        term: originationChannel,
        translate,
      }),
      originator: getOriginatorDisplayNameHeyLight(originatorName),
      paymentPlanId,
      praticaId,
      rawStatus: activityStatus,
      storeNumber,
      productExternalId,
      productSku,
    });
  }) ?? [];
