import { ToCamelCase } from '@heidi-pay/heidi-common-fe/types';
import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { GetLocaleCurrencyValue, Translate } from '@hooks/useI18n';
import {
  INameAndUuid,
  IOpsPortalMerchantConfigs,
} from '@schemas/opsPortal/types/merchantConfigs';
import { IOrderProfile } from '@schemas/opsPortal/types/merchantDetails';
import { convertBooleanToYesOrNo } from '@utils/convertBooleanToYesOrNo';
import { sort } from '@utils/sort';
import { IOrderProfileTableData } from './types';

interface IMapOrderProfileData {
  configs?: IOpsPortalMerchantConfigs;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  orderProfiles: ToCamelCase<IOrderProfile>[];
  translate: Translate;
}

export const mapOrderProfileData = ({
  configs,
  getLocaleCurrencyValue,
  orderProfiles,
  translate,
}: IMapOrderProfileData) =>
  [...orderProfiles]
    .sort((a, b) => sort(Number(a.ovLowerLimit), Number(b.ovLowerLimit)))
    .map(item => {
      const riskConfigName = configs?.risk_configs?.find(
        (config: INameAndUuid) => config.uuid === item.riskConfig,
      )?.name;
      const waterfallConfigName = configs?.waterfall_configs?.find(
        (config: INameAndUuid) => config.uuid === item.waterfallConfig,
      )?.name;

      return addPlaceholderForEmptyValues<IOrderProfileTableData>({
        name: item.name,
        orderProfileUuid: item.uuid,
        productName: item.productName,
        subProductName: item.subproductName,
        installmentMode: item.installmentMode,
        delayFinalisation: convertBooleanToYesOrNo(
          item.delayFinalisation,
          translate,
        ),
        routingLowerLimit: getLocaleCurrencyValue({
          currency: item.ovLowerLimitCurrency,
          value: item.ovLowerLimit,
        }),
        routingUpperLimit: getLocaleCurrencyValue({
          currency: item.ovUpperLimitCurrency,
          value: item.ovUpperLimit,
        }),
        maxContractBalanceLimit:
          item.maxContractBalanceLimit &&
          item.maxContractBalanceLimitCurrency &&
          getLocaleCurrencyValue({
            currency: item.maxContractBalanceLimitCurrency,
            value: item.maxContractBalanceLimit,
          }),
        dynamicDepositEnabled: convertBooleanToYesOrNo(
          item.dynamicDepositEnabled,
          translate,
        ),
        numberOfInstallmentsChoices:
          item.numberOfInstallmentsChoices.join(', '),
        minimumRiskAcceptanceLevel: item.minimumRiskAcceptanceLevel.toString(),
        depositPercentage: item.depositPercentage?.toString() ?? '-',
        residualPercentage: item.residualPercentage?.toString() ?? '-',
        delayFinalInstallment: convertBooleanToYesOrNo(
          item.delayFinalInstallment,
          translate,
        ),
        awaitingDispatchTimeout: item.awaitingDispatchTimeout,
        externalOriginatorProfileId: item.externalOriginatorProfileId,
        productGroup: item.productGroup?.name,
        commissionConfig: item.commissionConfig?.displayName,
        communicationConfiguration: item.communicationConfigUuid,
        feeConfig: item.feeConfig?.displayName || item.feeConfig?.uuid,
        paymentScheduleConfig: item.paymentScheduleConfig.name,
        waterfallConfig: item.waterfallConfig,
        waterfallConfigName,
        totalExposurePerClient: getLocaleCurrencyValue({
          currency: item.totalExposurePerClientCurrency,
          value: item.totalExposurePerClient.amount,
        }),
        autoDispatchOnManualAgreementSigningComplete: convertBooleanToYesOrNo(
          item.autoDispatchOnManualAgreementSigningComplete,
          translate,
        ),
        riskConfig: item.riskConfig,
        riskConfigName,
        contractType: item.contractType,
        isManualAgreementSigning: convertBooleanToYesOrNo(
          item.isManualAgreementSigning,
          translate,
        ),
      });
    });
