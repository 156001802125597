import { IAddressValues } from '../types';

const getWithDelimiter = (base: string, value: string, delimiter = ',') => {
  if (value === '' || base === '') {
    return value;
  }
  return `${delimiter} ${value}`;
};

export const getFormattedAddress = (formValues: IAddressValues) =>
  [
    { key: 'address_second_line', delimiter: '' },
    { key: 'address_first_line', delimiter: ',' },
    { key: 'address_street_number', delimiter: '' },
    { key: 'address_town', delimiter: ',' },
    { key: 'address_post_code', delimiter: '' },
    { key: 'address_country', delimiter: ',' },
  ].reduce((formattedAddress, current) => {
    return (
      formattedAddress +
      getWithDelimiter(
        formattedAddress,
        formValues[current.key as keyof typeof formValues] ?? '',
        current.delimiter,
      )
    );
  }, '');
