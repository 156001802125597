import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils';
import { IAddressField } from '@heidi-pay/heidi-component-library/components/SwissPostAddress';
import { IAddress } from '@schemas/consumerPortal/types/addressData';

export const formatSwissPostalAddress = (address: IAddress): IAddressField => {
  if (address === null) {
    return {};
  }

  const province = address.region_iso3166_2?.split('-').pop();

  return addPlaceholderForEmptyValues<IAddressField>(
    {
      city: address.locality,
      country: address.country_alpha_2,
      province,
      streetName: address.street,
      streetNumber: address.street_number?.toString(),
      zipCode: address.zip_code,
    },
    '',
  );
};
