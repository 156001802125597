import { addPlaceholderForEmptyValues } from '@heidi-pay/heidi-common-fe/utils/addPlaceholderForEmptyValues';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IClawbacksCombinedData, IClawbacksTableColumn } from './types';

interface IMapClawbacksTableData {
  data?: IClawbacksCombinedData[];
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapClawbacksTableData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IMapClawbacksTableData): IClawbacksTableColumn[] =>
  data?.map((dataRow: IClawbacksCombinedData) => {
    const {
      amount,
      bank_reference: bankReference,
      created_date: createdDate,
      external_uuid: externalContractUuid,
      merchant_name: merchantName,
      merchant_status: merchantStatus,
      merchant_uuid: merchantUuid,
      payment_date: paymentDate,
      payment_plan: paymentPlan,
    } = dataRow;

    const createdDateStr = createdDate?.toString();
    const paymentDateStr = paymentDate?.toString();

    return addPlaceholderForEmptyValues<IClawbacksTableColumn>({
      amount: getLocaleCurrencyValue({
        currency: amount?.currency,
        value: amount?.amount,
      }),
      contract: paymentPlan,
      createdDate: createdDateStr
        ? getLocaleDate({ date: createdDateStr })
        : '',
      merchant: merchantName,
      merchantStatus,
      paymentDate: paymentDateStr
        ? getLocaleDate({ date: paymentDateStr })
        : '',
      paymentReference: bankReference,
      paymentStatus: paymentDate
        ? translate('OPS_PORTAL.LABEL.SETTLED')
        : translate('OPS_PORTAL.LABEL.UNSETTLED'),
      hiddenContractExternalUuid: externalContractUuid,
      hiddenMerchantUuid: merchantUuid,
    });
  }) ?? [];
