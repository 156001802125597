import { IAddressField } from '@heidi-pay/heidi-component-library/components/SwissPostAddress';

export const formatSwissAddress = ({
  city,
  country,
  streetName,
  streetNumber,
  zipCode,
}: IAddressField) => {
  const streetParts = [streetName, streetNumber].filter(Boolean).join(' ');
  const zipCityParts = [zipCode, city].filter(Boolean).join(' ');
  const countryPart = country || '';
  return [streetParts, zipCityParts, countryPart].filter(Boolean).join(', ');
};
