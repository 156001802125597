/* eslint-disable react/jsx-no-bind */
import { useCallback } from 'react';
import { ClickableCell } from '@components/ClickableCell/ClickableCell';
import {
  TableColumns,
  createTableColumnsV2,
} from '@components/TableV2/helpers/createTableColumnsV2';
import {
  IBucketAssignmentCountData,
  IUseGetAggregatedAgencyAssignmentsColumns,
} from '../../types';
import { getBucketColumn } from '../../utils/getBucketColumn';

export const useGetAggregatedAgencyAssignmentsColumnsDays = ({
  dates,
  handleAgencyScreenChange,
  translate,
}: IUseGetAggregatedAgencyAssignmentsColumns) => {
  const handleCellClick = useCallback(
    (row: IBucketAssignmentCountData, date: string) => {
      handleAgencyScreenChange({
        isOnAggregateScreen: false,
        filters: {
          agencyName: '',
          assignmentStartDateFrom: date,
          assignmentStartDateTo: date,
          bucketName: row.bucket,
        },
      });
    },
    [handleAgencyScreenChange],
  );

  const datesColumns: TableColumns<IBucketAssignmentCountData> = dates.map(
    date => ({
      field: date,
      label: date,
      mapCustomJSX: ({ row }) => {
        const count = row.assignmentCounts?.[date] ?? 0;
        const handleClick = () => handleCellClick(row, date);
        return count ? (
          <ClickableCell onClick={handleClick} text={count} />
        ) : (
          count
        );
      },
    }),
  );

  const columns: TableColumns<IBucketAssignmentCountData> = [
    getBucketColumn(translate),
    ...datesColumns,
  ];

  return createTableColumnsV2({ columns });
};
