import { useCallback, useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { usePortal } from '@hooks/usePortal';
import { ContentInnerWrapper } from './Auth.styles';
import { AuthFormLoginUserPassword } from './components/AuthForm';
import { AuthFormLoginMFA } from './components/AuthForm/AuthFormLoginMFA';
import { AuthFormMFASetup } from './components/AuthForm/AuthFormMFASetup';
import {
  IHandleStepChangeProps,
  IStepChangeOptions,
} from './components/AuthForm/types';
import { getSecretFromTotpUrl } from './components/utils/getSecretFromTotpUrl';
import { LoginAuthStep } from './enums';

const StepContentWrapper = styled('div')({
  position: 'relative',
  width: '100%',
});

export const Login = () => {
  const defaultStepOptions = { totpUrlString: '', isSkippable: false };

  const { isConsumerPortal } = usePortal();
  const [step, setStep] = useState(LoginAuthStep.Step1LoginPage);
  const [show, setShow] = useState(true);
  const [accessTokenUserPass, setAccessTokenUserPass] = useState('');
  const [secret, setSecret] = useState('');
  const [stepOptions, setStepOptions] =
    useState<IStepChangeOptions>(defaultStepOptions);

  useEffect(() => {
    setSecret(getSecretFromTotpUrl(stepOptions?.totpUrlString ?? ''));
  }, [stepOptions?.totpUrlString]);

  const handleStepChange = useCallback(
    ({ accessToken, options = {}, step: nextStep }: IHandleStepChangeProps) => {
      const { isSkippable, totpUrlString } = options;

      setShow(false);
      setAccessTokenUserPass(accessToken ?? '');
      const updatedStepOptions = {
        ...(totpUrlString ? { totpUrlString } : {}),
        ...(isSkippable !== undefined ? { isSkippable } : {}),
      };

      setStepOptions(updatedStepOptions);

      setTimeout(() => {
        setStep(nextStep);
        setShow(true);
      }, 500);
    },
    [setStep, setShow],
  );

  return (
    <ContentInnerWrapper isSetup={step === LoginAuthStep.Step3MfaSetupPage}>
      <Fade in={show} timeout={500}>
        <StepContentWrapper>
          {step === LoginAuthStep.Step1LoginPage ? (
            <AuthFormLoginUserPassword
              handleStepChange={handleStepChange}
              isConsumerPortal={isConsumerPortal}
            />
          ) : null}
          {step === LoginAuthStep.Step2MfaCodeEntryPage ? (
            <AuthFormLoginMFA
              accessTokenUserPass={accessTokenUserPass}
              handleStepChange={handleStepChange}
              isSkippable={stepOptions?.isSkippable}
            />
          ) : null}
          {step === LoginAuthStep.Step3MfaSetupPage ? (
            <AuthFormMFASetup
              accessTokenUserPass={accessTokenUserPass}
              handleStepChange={handleStepChange}
              isSkippable={stepOptions?.isSkippable}
              secret={secret}
              totpUrl={stepOptions?.totpUrlString}
            />
          ) : null}
        </StepContentWrapper>
      </Fade>
    </ContentInnerWrapper>
  );
};
