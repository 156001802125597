import { useEffect, useState } from 'react';
import {
  BaseColour,
  HeyLightColour,
} from '@heidi-pay/heidi-component-library/enums';
import { styled } from '@mui/material';

const TimerWrapper = styled('div')({
  position: 'relative',
  width: '32px',
  height: '32px',
});

const Circle = styled('circle')({
  stroke: HeyLightColour.BrandAccentsHeyLightRed,
  strokeLinecap: 'round',
  transition: 'stroke-dashoffset 1s linear',
});

const TimeText = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '0.85rem',
  fontWeight: 600,
});

export const TOTPCountdownTimer = () => {
  const [secondsLeft, setSecondsLeft] = useState(30);

  useEffect(() => {
    const calculateSecondsLeft = () => {
      const epochSeconds = Math.floor(Date.now() / 1000);
      return 30 - (epochSeconds % 30);
    };

    setSecondsLeft(calculateSecondsLeft());

    const interval = setInterval(() => {
      setSecondsLeft(calculateSecondsLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const radius = 15;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (secondsLeft / 30) * circumference;

  return (
    <TimerWrapper>
      <svg height="32" width="32">
        <circle
          cx="16"
          cy="16"
          fill="transparent"
          r={radius}
          stroke={BaseColour.Grey5}
          strokeWidth="2"
        />
        <Circle
          cx="16"
          cy="16"
          fill="transparent"
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeWidth="2"
        />
      </svg>
      <TimeText>{secondsLeft}s</TimeText>
    </TimerWrapper>
  );
};
