import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldRHF } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { getValidationSchema } from './getValidationSchema';
import { ISubmitHandlerProps } from './types';

interface ICreateClawbackFormProps {
  currency?: string;
  formID: string;
  handleSubmit: (data: ISubmitHandlerProps) => void;
  maxAmount?: string;
}

export const CreateClawbackForm = ({
  currency,
  formID,
  handleSubmit,
  maxAmount,
}: ICreateClawbackFormProps) => {
  const { translate } = useI18n();

  const methods = useForm<ISubmitHandlerProps>({
    mode: 'onChange',
    defaultValues: { amount: '' },
    resolver: yupResolver(getValidationSchema({ maxAmount, translate })()),
  });

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextFieldRHF
          id="amount"
          label={`${translate(
            'OPS_PORTAL.LABEL.AMOUNT_TO_CLAWBACK',
          )} (${currency})`}
          name="amount"
        />
      </form>
    </FormProvider>
  );
};
