import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { objectKeysToSnakeCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToSnakeCase';
import {
  AddressSwissPost,
  useSwissPost,
  IAddressField,
} from '@heidi-pay/heidi-component-library/components/SwissPostAddress';
import { heyLightChTheme } from '@heidi-pay/heidi-component-library/themes/heyLightTheme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { usePortal } from '@hooks/usePortal';
import { IAddress } from '@schemas/consumerPortal/types/addressData';
import { Endpoints } from '@utils/enums';
import { formatSwissAddress } from '@utils/formatSwissAddress';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { AddressChangeSummary } from '../AddressChangeSummary';
import { RawAddressFormatType } from '../enums';
import { AddressSubmit } from '../types';
import { formatSwissPostalAddress } from './formatSwissPostalAddress';
import { useGetAddressSwissPostLabels } from './useGetAddressSwissPostLabels';

export const themeHeyLightCh = createTheme(heyLightChTheme);

export interface ISwissPostalAddressForm {
  formID: string;
  initialAddress: IAddress;
  onSubmit: AddressSubmit;
}

export const SwissPostalAddressForm = ({
  formID,
  initialAddress,
  onSubmit,
}: ISwissPostalAddressForm) => {
  const { portal } = usePortal();
  const address = formatSwissPostalAddress(initialAddress);
  const formLabels = useGetAddressSwissPostLabels();

  const getSwissPostResults = async (requestData: IAddressField) => {
    try {
      return postDataToEndpoint<IAddressField>({
        endpoint: Endpoints.FetchUserAddressSwissPost,
        requestData: objectKeysToSnakeCase(requestData),
        options: { search: `?portal=${portal}` },
      });
    } catch (error) {
      return {};
    }
  };

  const { formHandlers, formHelpers, formState, methods } = useSwissPost({
    defaultValue: address,
    getSwissPostResults,
    formLabels,
  });

  const formValues = methods.watch();

  const handleSubmit = useCallback(
    (addressField: IAddressField) => {
      onSubmit({
        address: { swiss_postal_address: objectKeysToSnakeCase(addressField) },
        addressFormat: RawAddressFormatType.SwissPostal,
      });
    },
    [onSubmit],
  );

  return (
    <ThemeProvider theme={themeHeyLightCh}>
      <FormProvider {...methods}>
        <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
          <AddressSwissPost
            control={methods.control}
            formHandlers={formHandlers}
            formHelpers={formHelpers}
            formLabels={formLabels}
            formState={formState}
            name={formID}
            options={{
              hiddenFields: ['country'],
              isExpandable: false,
            }}
            showAddressTitle={false}
            submitAttempted={true}
          />
        </form>
        <AddressChangeSummary
          initialFormattedAddress={initialAddress?.formatted_address}
          newFormattedAddress={formatSwissAddress(formValues)}
        />
      </FormProvider>
    </ThemeProvider>
  );
};
