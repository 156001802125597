import { Tabs } from '@components/Tabs';
import { useContractDocumentsTab } from '@hooks/useDocumentsTab';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { useHasPermission } from '@hooks/useHasPermission';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { TabEventKeys } from '@utils/constants';
import {
  useAssignmentHistoryTab,
  useCertificatesOfInterestTab,
  useContractOverviewTab,
} from '../hooks';
import { useCardAttemptsTab } from '../hooks/useCardAttemptsTab';
import { useContractCustomerDetailsTab } from '../hooks/useContractConsumerTab';
import { useContractDetailsTab } from '../hooks/useContractDetailsTab';
import { useContractPaymentScheduleHistoryTab } from '../hooks/useContractPaymentScheduleHistoryTab';
import { useContractPaymentScheduleTab } from '../hooks/useContractPaymentScheduleTab';
import { useContractPaymentsTab } from '../hooks/useContractPaymentsTab';
import { useContractPerformanceTab } from '../hooks/useContractPerformanceTab';
import { useCrabbTab } from '../hooks/useCrabbTab';
import { useLateFeesTab } from '../hooks/useLateFeesTab';

interface ITabsComponentProps {
  collectionsBlockAutoReferralData?: IOpsPortalBlockExternalCollectionsReferral;
  contractData: IContractDetails;
  contractUuid?: string;
  externalContractUUID?: string;
  isArrearsToBeManagedByHeidi: boolean;
  isCompass: boolean;
  isInternalOpsUser: boolean;
  monetaContractData?: IOpsPortalMonetaContract;
  pauseCommunicationsData?: IOpsPortalPauseCommunications;
}

export const TabsComponent = ({
  collectionsBlockAutoReferralData,
  contractData,
  contractUuid,
  externalContractUUID,
  isArrearsToBeManagedByHeidi,
  isCompass,
  isInternalOpsUser,
  monetaContractData,
  pauseCommunicationsData,
}: ITabsComponentProps) => {
  const { isExperimentalUIEnabled = false } = useFeatureFlags();
  const opsCanViewContractDocumentsTab = useHasPermission([
    'ops_portal.can_view_customer_agreements',
  ]);

  const opsCanViewCertificateOfInterestTab = useHasPermission([
    'ops_portal.list_certificate_of_interests',
  ]);

  const opsCanViewCrabbTabb = useHasPermission([
    'ops_portal.can_view_crabb_salesforce_requests',
  ]);

  const opsCanViewLateFeesTab = useHasPermission([
    'ops_portal.can_view_collections_late_fees',
  ]);

  const contractOverviewTab = useContractOverviewTab({
    collectionsBlockAutoReferralData,
    contractData,
    contractUuid,
    isArrearsToBeManagedByHeidi,
    isCompass,
    isInternalOpsUser,
    pauseCommunicationsData,
  });

  const contractDetailsTab = useContractDetailsTab({ contractData });
  const contractPerformanceTab = useContractPerformanceTab({ contractData });
  const customerDetailsTab = useContractCustomerDetailsTab({ contractData });
  const contractPaymentScheduleTab =
    useContractPaymentScheduleTab(monetaContractData);
  const contractPaymentsTab = useContractPaymentsTab(monetaContractData);
  const assignmentHistoryTab = useAssignmentHistoryTab(externalContractUUID);
  const contractPaymentScheduleHistoryTab =
    useContractPaymentScheduleHistoryTab(monetaContractData);
  const certificatesOfInterestTab = useCertificatesOfInterestTab({
    externalContractUUID,
  });
  const contractDocumentsTab = useContractDocumentsTab(
    contractData.contract_uuid,
  );
  const cardAttemptTab = useCardAttemptsTab(externalContractUUID);
  const crabbTab = useCrabbTab(externalContractUUID);
  const lateFeesTab = useLateFeesTab({ contractUuid });

  const tabDataArray = [
    contractOverviewTab,
    ...(isExperimentalUIEnabled
      ? []
      : [contractDetailsTab, contractPerformanceTab, customerDetailsTab]),
    contractPaymentScheduleTab,
    contractPaymentsTab,
    assignmentHistoryTab,
    contractPaymentScheduleHistoryTab,
    ...(opsCanViewContractDocumentsTab ? [contractDocumentsTab] : []),
    ...(opsCanViewCertificateOfInterestTab ? [certificatesOfInterestTab] : []),
    cardAttemptTab,
    ...(opsCanViewCrabbTabb && contractData?.managed_by_crabb
      ? [crabbTab]
      : []),
    ...(opsCanViewLateFeesTab ? [lateFeesTab] : []),
  ];

  return (
    <Tabs
      initialTabKey={TabEventKeys.CONTRACT_OVERVIEW}
      tabsData={tabDataArray}
      tabsId="contract-drilldown-tabs"
    />
  );
};
