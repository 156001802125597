import { useParams } from 'react-router-dom';
import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { Box, styled } from '@mui/material';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { Section } from '@pages/ContractDetails/v2/components/Section';
import { QueryKeys, Views } from '@utils/constants';
import {
  useGetCollectionsQuery,
  useGetContractQuery,
} from '../../hooks/queries';
import { useGetMonetaBalanceQuery } from '../../hooks/queries/useGetMonetaBalanceDetailsQuery';
import { useCustomerSection } from '../hooks/useCustomerSection';
import { useGeneralSection } from '../hooks/useGeneralSection';
import { useMerchantSection } from '../hooks/useMerchantSection';
import { usePaymentsSection } from '../hooks/usePaymentsSection';
import { usePerformanceSection } from '../hooks/usePerformanceSection';

const TabContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const SectionsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: 3,
});

export const ContractOverviewTab = () => {
  const { uuid: externalContractUuid } = useParams();
  const view = Views.CONTRACTS;

  const contractDataQueryKey = [
    QueryKeys.CONTRACT_DETAILS_DATA,
    externalContractUuid,
  ];

  const { contractData, contractDataError, isContractDataLoading } =
    useGetContractQuery({
      contractDataQueryKey,
      queryKeyContractUuid: externalContractUuid,
      view,
    });

  const contractDetailsData = objectKeysToCamelCase(contractData);
  const { contractDetails } = contractDetailsData ?? {};
  const { contractUuid } = contractDetails ?? {};

  const { collectionsData, isInArrears } = useGetCollectionsQuery({
    contractUuid,
  });
  const collectionsDetails = objectKeysToCamelCase(collectionsData);

  const { monetaBalanceDetails: monetaBalanceData } = useGetMonetaBalanceQuery({
    contractUuid,
  });
  const monetaBalanceDetails = objectKeysToCamelCase(monetaBalanceData);

  const { customerSection } = useCustomerSection({
    contractDetails,
  });

  const { generalSection } = useGeneralSection({
    contractDetails,
    monetaBalanceDetails,
    isInArrears,
  });

  const { performanceSection } = usePerformanceSection({
    collectionsDetails,
    contractDetails,
    monetaBalanceDetails,
  });

  const { paymentsSection } = usePaymentsSection({
    contractDetails,
    monetaBalanceDetails,
  });

  const { merchantSection } = useMerchantSection({
    contractDetails,
  });

  return (
    <QueryStateRouter
      error={contractDataError}
      isLoading={isContractDataLoading}
    >
      <TabContainer>
        <SectionsContainer>
          <Section
            items={generalSection}
            titleKey="OPS_PORTAL.LABEL.GENERAL_INFO"
          />
        </SectionsContainer>

        <SectionsContainer>
          <Section
            items={customerSection}
            titleKey="OPS_PORTAL.LABEL.CUSTOMER_INFO"
          />
          <Section
            items={merchantSection}
            titleKey="OPS_PORTAL.LABEL.MERCHANT_INFO"
          />
        </SectionsContainer>

        <SectionsContainer>
          <Section
            items={performanceSection}
            titleKey="OPS_PORTAL.LABEL.PERFORMANCE_INFO"
          />
          <Section
            items={paymentsSection}
            titleKey="OPS_PORTAL.LABEL.PAYMENT_INFO"
          />
        </SectionsContainer>
      </TabContainer>
    </QueryStateRouter>
  );
};
