import axios from 'axios';
import { Originators } from '@utils/constants';

export const LOGIN_USER_PASS_URL = '/api/user/token';
export const LOGIN_MFA_URL = '/api/user/mfa/verify';

export const PASSWORD_RESET_URL = '/api/user/password-reset';
export const REQUEST_PASSWORD_URL = '/api/user/request-password-reset';
export const REQUEST_REGISTRATION_EMAIL_URL = '/api/user/request-registration';
export const ME_URL = '/api/user/getUserByToken';
export const USER_MFA_SETTINGS_URL = '/api/user/mfa/settings';

export function loginUserPassword(email, password) {
  return axios.post(LOGIN_USER_PASS_URL, { username: email, password });
}

export function getUserMfaSettings() {
  return axios.get(USER_MFA_SETTINGS_URL);
}

export function loginMFA(totp) {
  return axios.post(LOGIN_MFA_URL, { totp });
}

export function resetPassword(token, password) {
  return axios.post(PASSWORD_RESET_URL, { token, password });
}

export function requestPassword(email, language, originator) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
    language,
    // I'm doing this instead of using a default assignment because of typing
    // issues with ForgotPassword.tsx. The typing issues would go away if we
    // converted this file to TS, but doing so breaks the Cypress tests. When
    // https://heidi-pay.atlassian.net/browse/HC-11022 has been done, we can
    // go back to using a default parameter.
    originator: originator ?? Originators.HEIDIPAY,
  });
}

export function requestRegistrationEmail({ email, language, theme }) {
  return axios.post(REQUEST_REGISTRATION_EMAIL_URL, { email, language, theme });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function agreeToPrivacyPolicy() {
  return axios.put('/api/user/settings/agreeToPrivacyPolicy');
}
