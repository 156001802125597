import { TextField, InputAdornment } from '@mui/material';
import { CopyText } from '@components/CopyText/CopyText';
import { Translate } from '@hooks/useI18n';

interface ISecretKeyField {
  secret: string;
  translate: Translate;
}

export const SecretKeyField = ({ secret, translate }: ISecretKeyField) => (
  <TextField
    disabled={true}
    fullWidth={true}
    InputProps={{
      readOnly: true,
      endAdornment: (
        <InputAdornment position="end">
          <CopyText
            copyText={secret}
            errorMessage={translate(
              'OPS_PORTAL.LABEL.SETUP_MFA_SECRET_COPY_FAILED',
            )}
            successMessage={translate(
              'OPS_PORTAL.LABEL.SETUP_MFA_SECRET_COPY_SUCCESS',
            )}
          />
        </InputAdornment>
      ),
      sx: {
        color: 'black',
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'black',
        },
      },
    }}
    label={translate('OPS_PORTAL.LABEL.SETUP_MFA_SECRET_KEY')}
    value={secret}
  />
);
