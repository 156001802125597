import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useQuery } from '@tanstack/react-query';

import { usePortalError } from '@hooks/usePortalError';
import { IOpsPortalTemplateList } from '@schemas/opsPortal/types/templateList';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetEmailTemplates {
  enabled: boolean;
}

export const useGetEmailTemplates = ({ enabled }: IUseGetEmailTemplates) => {
  const { handleAxiosError } = usePortalError();
  const { data: templatesList, isLoading: isTemplatesListLoading } = useQuery<
    IOpsPortalTemplateList,
    AxiosError<IError>
  >(
    [QueryKeys.EMAIL_TEMPLATES_LIST],
    () => fetchDataFromEndpoint(Endpoints.FetchCustomerEmailTemplatesList),
    {
      onError: error => {
        handleAxiosError(
          error,
          'OPS_PORTAL.ERROR.UNABLE_TO_FETCH_EMAIL_TEMPLATES',
        );
      },
      enabled,
    },
  );

  return { templatesList, isTemplatesListLoading };
};
